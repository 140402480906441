import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { MediaModel } from "../../../lib/models";
import theme from "../../../lib/theme";
import configs from "../../../lib/configs";

interface PageBackgroundTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: object;
}

export class PageBackground
    extends Lightning.Component<PageBackgroundTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PageBackgroundTemplateSpec>
{
    _timeout: undefined | number;
    _lastSrc: undefined | string;
    _index = 0;

    _boundEventHandlers: any = {};

    readonly Background = this.getByRef("Background")!;

    static override _template(): Lightning.Component.Template<PageBackgroundTemplateSpec> {
        return {
            alpha: 1,
            w: theme.layout.w,
            h: theme.layout.h,
            rect: true,
            color: Colors(theme.color.bg.primary).get(),
            Background: {
                w: (w) => w,
                h: (h) => h,
                src: configs.background.hasStaticImage ? Utils.asset("images/bg-placeholder.png") : undefined
            }
        };
    }

    override _setup() {
        this._boundEventHandlers = {
            hideBackground: this._hideBackground.bind(this),
            showBackground: this._showBackground.bind(this),
            clearBackground: this._clearBackground.bind(this)
        };
    }

    override _attach() {
        this.application.on("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.on("showBackground", this._boundEventHandlers.showBackground);
        this.application.on("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _detach() {
        this.application.off("hideBackground", this._boundEventHandlers.hideBackground);
        this.application.off("showBackground", this._boundEventHandlers.showBackground);
        this.application.off("clearBackground", this._boundEventHandlers.clearBackground);
    }

    override _init() {}

    _clearBackground() {}

    _hideBackground() {
        this.patch({
            alpha: 0
        });
    }

    _showBackground() {
        this.patch({
            alpha: 1
        });
    }

    _updateBackground(movie: MediaModel) {}
}
