import { Search } from "./Search";
import widgets from "../../lib/widgets";

export default [
    {
        path: "search",
        component: Search,

        widgets: widgets
    }
];
