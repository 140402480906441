import { Colors, Lightning, Router } from "@lightningjs/sdk";

import { TranslatableText } from "../../components/data-display/TranslatableText/TranslatableText";
import { Button } from "../../components/input/Button/Button";
import theme from "../../lib/theme";

interface ExitTemplateSpec extends Lightning.Component.TemplateSpec {
    Box: {
        Background: object;
        Content: {
            Label: typeof TranslatableText;
            Actions: {
                Cancel: typeof Button;
                Confirm: typeof Button;
            };
        };
    };
}

export class Exit
    extends Lightning.Component<ExitTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<ExitTemplateSpec>
{
    _resolve!: (value: boolean) => void;

    readonly Box = this.getByRef("Box")!;
    readonly Content = this.Box.getByRef("Content")!;
    readonly Actions = this.Content.getByRef("Actions")!;
    readonly Cancel = this.Actions.getByRef("Cancel")!;
    readonly Confirm = this.Actions.getByRef("Confirm")!;

    static override _template(): Lightning.Component.Template<ExitTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.bg.overlay).alpha(0.6).get(),
            alpha: 0,
            zIndex: 40,
            collision: true,
            Box: {
                collision: true,
                w: 580,
                h: 320,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        580,
                        320,
                        12,
                        0,
                        undefined,
                        true,
                        Colors(theme.color.bg.secondary).get()
                    )
                },
                Content: {
                    collision: true,
                    w: (w) => w,
                    h: (h) => h,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (h) => h / 2,
                    flex: {
                        direction: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    },
                    flexItem: { grow: 1 },
                    Label: {
                        type: TranslatableText,
                        key: "exit.title",
                        text: {
                            fontSize: 32
                        }
                    },
                    Actions: {
                        collision: true,
                        flexItem: { marginTop: 36 },
                        w: 360,
                        h: 56,
                        flex: {
                            direction: "row",
                            alignItems: "flex-start",
                            justifyContent: "space-between"
                        },
                        Cancel: {
                            type: Button,
                            zIndex: 50,
                            label: "actions.no",
                            w: 160,
                            signals: {
                                handleClick: "_handleCancel"
                            }
                        },
                        Confirm: {
                            type: Button,
                            zIndex: 50,
                            label: "actions.yes",
                            w: 160,
                            signals: {
                                handleClick: "_handleConfirm"
                            }
                        }
                    }
                }
            }
        };
    }

    override _getFocused(): any {
        return this;
    }

    async open(resolve: (val: boolean) => void) {
        this._resolve = resolve;
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleRight() {
        // needed to prevent focus on page
    }

    override _handleBack() {
        Router.focusPage();
    }

    override _handleDown() {
        //
    }

    _handleClick() {
        //
    }

    override _active() {
        this._setState("CloseState");
    }

    _handleCancel() {
        this._setState("CloseState");
        this._handleCancel();
    }

    _handleConfirm() {
        this._setState("ConfirmState");
        this._handleConfirm();
    }

    static override _states() {
        return [
            class CloseState extends this {
                override _getFocused() {
                    return this.Cancel;
                }

                override _handleEnter() {
                    this._resolve(false);
                    Router.focusPage();
                }

                override _handleCancel() {
                    this._handleEnter();
                }

                override _handleRight() {
                    this._setState("ConfirmState");
                }
            },
            class ConfirmState extends this {
                override _getFocused() {
                    return this.Confirm;
                }

                override _handleEnter() {
                    this._resolve(true);
                }

                override _handleLeft() {
                    this._setState("CloseState");
                }

                override _handleConfirm() {
                    this._handleEnter();
                }
            }
        ];
    }
}
