import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import theme from "../../../lib/theme";

interface KeyData {
    origin: string; //raw string provided in layout
    key: string; //single character or string
    label: string; //what the key component should show on screen
    action: string; //what kind of action the key should fire on key press
}

interface KeyboardKeyTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: object;
    Label: object;
}

export class KeyboardKey
    extends Lightning.Component<KeyboardKeyTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<KeyboardKeyTemplateSpec>
{
    static override _template(): Lightning.Component.Template<KeyboardKeyTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Background: {
                w: (w) => w,
                h: (h) => h,
                rect: true
            },
            Label: {
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                h: 28,
                text: {
                    textAlign: "center",
                    fontSize: 24
                }
            }
        };
    }

    _data: KeyData = {
        origin: "",
        key: "",
        label: "",
        action: ""
    };
    _labelText = {};
    _icon = "";
    // _activeIcon = "";

    readonly Label = this.getByRef("Label")!;
    readonly Background = this.getByRef("Background")!;

    static get width() {
        return 92;
    }

    static get height() {
        return 92;
    }

    set data(value: KeyData) {
        this._data = value;

        this._update();
    }

    get data() {
        return this._data;
    }

    set labelText(obj) {
        this._labelText = obj;
        this.Label.patch({ text: obj });
    }

    get labelText() {
        return this._labelText;
    }

    set label(obj) {
        // this.Label.patch(obj);
    }

    get label() {
        return this.tag("Label");
    }

    set icon(src: string) {
        this._icon = `icons/${src}.png`;

        this._update();
    }

    get icon() {
        return this._icon;
    }

    _update() {
        if (!this.active) {
            return;
        }
        const { label = "" } = this._data;
        const hasFocus = this.hasFocus();

        this.Label.patch({
            text: { text: label === "Space" ? label : label }
        });

        this._setIcon();
        this._createBackground(hasFocus);

        this.Label.patch({
            y: this._icon || (!this._icon && !isNaN(parseInt(label))) ? (h) => h / 2 : (h) => h / 2 - 2
        });
    }

    override _active() {
        this._update();
    }

    override _focus() {
        if (!this._icon) {
            this.Label.patch({
                text: {
                    textColor: Colors(theme.keyboard.key.text.colorFocus).get()
                }
            });
        }
        this._createBackground(true);
    }

    override _unfocus() {
        if (!this._icon) {
            this.Label.patch({
                text: {
                    textColor: Colors(theme.keyboard.key.text.color).get()
                }
            });
        }
        this._createBackground(false);
    }

    _setIcon() {
        if (this._icon) {
            this.Label.patch({
                texture: Lightning.Tools.getSvgTexture(Utils.asset(this._icon), 28, 28)
            });
        }
    }

    _createBackground(isActive: boolean) {
        this.Background.patch({
            texture: Lightning.Tools.getRoundRect(
                this.w,
                this.w,
                6,
                0,
                undefined,
                true,
                isActive ? Colors(theme.keyboard.key.bg.colorFocus).get() : Colors(theme.keyboard.key.bg.color).get()
            )
        });
    }

    _handleClick() {
        this.fireAncestors("$focusKey", this._data.key);
    }
}
