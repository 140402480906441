import { Colors, Img, Lightning, Registry, Router, Storage, Utils } from "@lightningjs/sdk";
import { TranslatableText } from "../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../lib/theme";
import { Button } from "../../components/input/Button/Button";
import { createPostTvSubscription } from "../../api/mutations";
import { Loading } from "../../components/feedback/Loading/Loading";
import { STORAGE_KEYS, deviceId } from "../../lib/utils";
import configs from "../../lib/configs";
import posthog from "posthog-js";
import { Error } from "../../components/feedback/Error/Error";

interface PostTvSubscriptionTemplateSpec extends Lightning.Component.TemplateSpec {
    Error: typeof Error;
    Loading: typeof Loading;
    Box: {
        Background: object;
        Content: {
            LogoContainer: {
                Logo: object;
            };
            Price: typeof TranslatableText;
            Actions: {
                Cancel: typeof Button;
                Confirm: typeof Button;
            };
        };
    };
}

export class PostTvSubscription
    extends Lightning.Component<PostTvSubscriptionTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PostTvSubscriptionTemplateSpec>
{
    _loading = false;
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    readonly Loading = this.getByRef("Loading")!;
    readonly Error = this.getByRef("Error")!;
    readonly Box = this.getByRef("Box")!;
    readonly Content = this.Box.getByRef("Content")!;
    readonly Price = this.Content.getByRef("Price")!;
    readonly Actions = this.Content.getByRef("Actions")!;
    readonly Cancel = this.Actions.getByRef("Cancel")!;
    readonly Confirm = this.Actions.getByRef("Confirm")!;

    static override _template(): Lightning.Component.Template<PostTvSubscriptionTemplateSpec> {
        return {
            zIndex: 40,
            w: theme.layout.w,
            h: theme.layout.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.bg.overlay).alpha(0.8).get(),
            alpha: 0,
            collision: true,
            Box: {
                w: 1180,
                h: 640,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        1180,
                        640,
                        12,
                        0,
                        undefined,
                        true,
                        Colors(theme.color.bg.primary).get()
                    )
                },
                Content: {
                    w: (w) => w,
                    h: (h) => h,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    LogoContainer: {
                        h: 200,
                        w: 300,
                        Logo: {
                            mount: 0.5,
                            x: (w) => w / 2,
                            y: (h) => h / 2 - 20,
                            texture: Img(Utils.asset("images/logo.png")).contain(theme.logo.w, theme.logo.h)
                        }
                    },
                    Price: {
                        flexItem: { marginBottom: 24 },
                        type: TranslatableText,
                        translate: {
                            key: "subscription.posttv",
                            value: ""
                        },
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 28,
                            lineHeight: 30,
                            wordWrap: true,
                            wordWrapWidth: 1100,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    },
                    Actions: {
                        collision: true,
                        flexItem: { marginTop: 36 },
                        w: 380,
                        h: 56,
                        flex: {
                            direction: "row",
                            alignItems: "flex-start",
                            justifyContent: "space-between"
                        },
                        Cancel: {
                            type: Button,
                            zIndex: 50,
                            label: "actions.cancel",
                            w: 170,
                            signals: {
                                handleClick: "_handleCancel"
                            }
                        },
                        Confirm: {
                            type: Button,
                            zIndex: 50,
                            label: "actions.subscribe",
                            w: 170,
                            signals: {
                                handleClick: "_handleConfirm"
                            }
                        }
                    }
                }
            },
            Error: {
                type: Error,
                alpha: 0,
                zIndex: 60
            },
            Loading: {
                type: Loading,
                alpha: 0,
                delay: 1,
                zIndex: 60
            }
        };
    }

    override _active() {
        this.Price.patch({
            translate: {
                key: "subscription.posttv",
                value: {
                    appName: configs.appName,
                    value: (Storage.get("subscriptionData")?.price || "") + "€"
                }
            }
        });

        this._setState("CloseState");
    }

    override _getFocused(): any {
        return this.Confirm;
    }

    override _focus() {
        this.patch({
            Box: {
                alpha: 1
            },
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        if (this._loginTimeout) {
            Registry.clearTimeout(this._loginTimeout);
            this._loginTimeout = undefined;
        }

        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
        this._setState("CloseState");
    }

    override _handleRight() {
        this._setState("ConfirmState");
    }

    override _handleEnter() {
        console.log("ENTER");

        this._setState("LoadingState");

        (async () => {
            try {
                const { token, ...user } = await createPostTvSubscription();
                Storage.set(STORAGE_KEYS.user, user);

                if (user.subscribed) {
                    posthog.capture("SuccessfulAuth", {
                        token: await deviceId()
                    });

                    this._loginTimeout = Registry.setTimeout(() => {
                        this._loginTimeout = undefined;

                        this._setState("");

                        Router.focusPage();
                    }, 30000);
                } else {
                    this._setState("");
                }
            } catch (e) {
                this._setState("ErrorState");
            }
        })();
    }

    override _handleBack() {
        Router.focusPage();
    }

    override _handleDown() {
        //
    }

    _handleClick() {
        this._handleBack();
    }

    static override _states() {
        return [
            class CloseState extends this {
                override _getFocused() {
                    return this.Cancel;
                }

                override _handleEnter() {
                    console.log("ENTER");

                    Router.focusPage();
                }

                override _handleRight() {
                    this._setState("");
                }
            },
            class ErrorState extends this {
                override $enter() {
                    this.Box.patch({
                        alpha: 0
                    });
                    this.Loading.patch({
                        alpha: 0
                    });

                    this.Error.patch({
                        alpha: 1
                    });
                }

                override $exit() {
                    this.Error.patch({
                        alpha: 0
                    });
                }

                override _getFocused() {
                    return this.Error;
                }

                override _handleEnter() {
                    Router.focusPage();
                }
            },
            class LoadingState extends this {
                override $enter() {
                    this.Box.patch({
                        alpha: 1
                    });
                    this.Loading.patch({
                        alpha: 1
                    });

                    this.Error.patch({
                        alpha: 0
                    });
                }

                override $exit() {
                    this.Loading.patch({
                        alpha: 0
                    });
                }

                override _getFocused() {
                    return this.Loading;
                }

                override _handleEnter() {}
                override _handleLeft() {}
                override _handleRight() {}
                override _handleUp() {}
                override _handleDown() {}
                override _handleClick() {}
            }
        ];
    }
}
