import { Colors, Lightning, Router, Utils } from "@lightningjs/sdk";
import { SUBSCRIPTION_LINK } from "../../lib/utils";
// @ts-expect-error no types available yet
import QRCode from "qrcode";
import { TranslatableText } from "../../components/TranslatableText/TranslatableText";
import theme from "../../lib/theme";
import { getPayAlteoxSubscriptionLink } from "../../api/mutations";

interface SubscriptionTemplateSpec extends Lightning.Component.TemplateSpec {
    Box: {
        Background: object;
        Content: {
            LogoContainer: {
                Logo: object;
            };
            Label: typeof TranslatableText;
            Price: typeof TranslatableText;
            QrCode: object;
            Link: object;
        };
    };
}

export class Subscription
    extends Lightning.Component<SubscriptionTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SubscriptionTemplateSpec>
{
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    readonly Box = this.getByRef("Box")!;
    readonly Content = this.Box.getByRef("Content")!;
    readonly QrCode = this.Content.getByRef("QrCode")!;
    readonly Link = this.Content.getByRef("Link")!;
    readonly Price = this.Content.getByRef("Price")!;

    static override _template(): Lightning.Component.Template<SubscriptionTemplateSpec> {
        return {
            zIndex: 40,
            w: theme.layout.screenW,
            h: theme.layout.screenH,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.overlay).alpha(0.6).get(),
            alpha: 0,
            collision: true,
            Box: {
                w: 500,
                h: 720,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        500,
                        720,
                        12,
                        0,
                        undefined,
                        true,
                        Colors(theme.color.container).get()
                    )
                },
                Content: {
                    w: (w) => w,
                    h: (h) => h,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    LogoContainer: {
                        h: 200,
                        w: 300,
                        Logo: {
                            scale: 2,
                            pivotY: 1,
                            pivotX: 0.5,
                            mount: 0.5,
                            x: (w) => w / 2,
                            y: (h) => h / 2,
                            texture: Lightning.Tools.getSvgTexture(
                                Utils.asset("images/logo.png"),
                                theme.logo.w,
                                theme.logo.h
                            )
                        }
                    },
                    // Label: {
                    //     flexItem: { marginBottom: 12 },
                    //     type: TranslatableText,
                    //     key: "subscription.title",
                    //     text: {
                    //         textAlign: "center",
                    //         fontFace: "SemiBold",
                    //         fontSize: 40,
                    //         wordWrap: true,
                    //         wordWrapWidth: 400
                    //     }
                    // },
                    Price: {
                        flexItem: { marginBottom: 24 },
                        type: TranslatableText,
                        translate: {
                            key: "subscription.price",
                            value: "5,99€"
                        },

                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 28,
                            wordWrap: true,
                            wordWrapWidth: 400
                        }
                    },
                    QrCode: { w: 360, h: 360 },
                    Link: {
                        flexItem: { marginTop: 16 },
                        h: 40,
                        text: {
                            text: "",
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 32
                        }
                    }
                }
            }
        };
    }

    override _getFocused() {
        return this;
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleBack() {
        Router.focusPage();
    }

    override _handleDown() {
        //
    }

    override async _attach() {
        const link = SUBSCRIPTION_LINK;

        // const data = await getPayAlteoxSubscriptionLink();

        this.Price.patch({
            translate: {
                key: "subscription.price",
                value: ""
                // value: data.monthlyPrice || "5,99€"
            }
        });

        const qrcode = await QRCode.toDataURL(link, {
            errorCorrectionLevel: "H",
            type: "image/jpeg",
            quality: 1
        });
        this.QrCode.patch({
            src: qrcode
        });
        this.Link.patch({
            text: {
                text: link
            }
        });
    }

    _handleClick() {
        this._handleBack();
    }
}
