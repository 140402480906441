import { Lightning } from "@lightningjs/sdk";
import { PlayerProgress } from "./components/PlayerProgress";
import { PlayPauseButton } from "./components/PlayPauseButton";
import theme from "../../../../lib/theme";
import { formatTime } from "../../../../lib/utils";

interface PlayerBottomTemplateSpec extends Lightning.Component.TemplateSpec {
    PlayPause: typeof PlayPauseButton;
    CurrentTime: object;
    Duration: object;
    Progress: typeof PlayerProgress;
    duration: number;
    currentTime: number;
}

export class PlayerBottom
    extends Lightning.Component<PlayerBottomTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerBottomTemplateSpec>
{
    _duration = 0;

    readonly PlayPause = this.getByRef("PlayPause")!;
    readonly CurrentTime = this.getByRef("CurrentTime")!;
    readonly Duration = this.getByRef("Duration")!;
    readonly Progress = this.getByRef("Progress")!;

    static override _template(): Lightning.Component.Template<PlayerBottomTemplateSpec> {
        return {
            w: theme.layout.w - 72 * 2,
            x: 72,
            flex: { direction: "row", alignItems: "center", justifyContent: "space-between" },
            PlayPause: {
                type: PlayPauseButton,
                signals: {
                    handleHover: "setPlayPauseState"
                }
            },
            CurrentTime: {
                y: 1,
                w: 100,
                flexItem: { marginLeft: 24 },
                text: {
                    text: "",
                    fontSize: 24,
                    fontFace: "Secondary-Regular"
                }
            },
            Progress: {
                // y: 50,
                w: theme.layout.w - theme.progress.offset * 2,
                h: 8,
                type: PlayerProgress
            },
            Duration: {
                // mountX: 1,
                y: 1,
                w: 100,
                text: {
                    text: "",
                    fontSize: 24,
                    fontFace: "Secondary-Regular",
                    textAlign: "right"
                }
            }
        };
    }

    override _getFocused() {
        return this.PlayPause;
    }

    override _active() {
        this._setState("PlayPauseState");

        if (window.selectedMovie?.__typename === "LiveStream") {
            this.PlayPause.patch({
                alpha: 0
            });
            this.CurrentTime.patch({
                alpha: 0
            });
            this.Progress.patch({
                alpha: 0
            });
            this.Duration.patch({
                alpha: 0
            });
        }
    }

    triggeredPlayPause() {
        this.PlayPause.triggeredPlayPause();
    }

    triggeredPause() {
        this.PlayPause.triggeredPause();
    }

    triggeredPlay() {
        this.PlayPause.triggeredPlay();
    }

    setPlayState(playing: boolean) {
        this.PlayPause.setPlayState(playing);
    }

    setRewindState() {
        this._setState("RewindState");
    }

    setPlayPauseState() {
        this._setState("PlayPauseState");
    }

    setForwardState() {
        this._setState("ForwardState");
    }

    override _handleRight() {
        this.fireAncestors("$triggerVideoForward");
    }

    override _handleLeft() {
        this.fireAncestors("$triggerVideoRewind");
    }

    override _handleEnter() {
        return false;
    }

    set duration(value: number) {
        this._duration = value;
        this.Duration.patch({
            text: {
                text: formatTime(value)
            }
        });
    }

    set currentTime(value: number) {
        this.CurrentTime.patch({
            text: {
                text: formatTime(value, {
                    hideEmpty: false,
                    useSeconds: true
                })
            }
        });

        if (this._duration) {
            this.Progress.patch({
                progress: value ? value / this._duration : 0
            });
        }
    }
}
