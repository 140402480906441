import { Colors, Lightning, Router } from "@lightningjs/sdk";
import { TranslatableText } from "../components/data-display/TranslatableText/TranslatableText";
import { Button } from "../components/input/Button/Button";
import theme from "../lib/theme";

interface ErrorPageTemplateSpec extends Lightning.Component.TemplateSpec {
    Box: {
        Text: typeof TranslatableText;
        Button: typeof Button;
    };
}

interface ErrorTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class ErrorPage
    extends Lightning.Component<ErrorPageTemplateSpec, ErrorTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<ErrorPageTemplateSpec>
{
    static override _template(): Lightning.Component.Template<ErrorPageTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            Box: {
                w: 560,
                h: 300,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                texture: Lightning.Tools.getRoundRect(
                    560,
                    300,
                    12,
                    0,
                    undefined,
                    true,
                    Colors(theme.color.bg.secondary).get()
                ),
                Text: {
                    type: TranslatableText,
                    key: "error.general",
                    text: {
                        fontSize: 32,
                        textAlign: "center",
                        wordWrap: true,
                        wordWrapWidth: 500
                    }
                },
                Button: {
                    flexItem: { marginTop: 36 },
                    w: 160,
                    type: Button,
                    label: "general.back"
                }
            }
        };
    }

    override _active() {
        this.application.emit("clearBackground");

        if (!navigator.onLine) {
            this.tag("Box.Text")!.patch({
                key: "error.connection"
            });
        } else {
            this.tag("Box.Text")!.patch({
                key: "error.general"
            });
        }
    }

    override _getFocused() {
        return this.tag("Box.Button")!;
    }

    override _handleEnter() {
        Router.back();
    }
}
