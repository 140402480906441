import { Colors, Lightning } from "@lightningjs/sdk";
import { Badge } from "../Badge/Badge";

interface MovieCategoryTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    Category: {
        Label: object;
        Badge: typeof Badge;
    };
    Name: object;
}

export class MovieCategory
    extends Lightning.Component<MovieCategoryTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieCategoryTemplateSpec>
{
    readonly Category = this.getByRef("Category")!;
    readonly Label = this.Category.getByRef("Label")!;

    static override _template(): Lightning.Component.Template<MovieCategoryTemplateSpec> {
        return {
            h: 40,
            Category: {
                flex: { direction: "row", alignItems: "center" },
                Label: {
                    flexItem: { marginRight: 22 },
                    text: {
                        text: "",
                        fontSize: 32,
                        textColor: Colors("text").alpha(0.6).get(),
                        fontFace: "SemiBold"
                    }
                }
            }
        };
    }

    set label(value: string) {
        this.Label.patch({ text: { text: value } });
    }
}
