import { Lightning } from "@lightningjs/sdk";
import { Icon } from "../../../../../components/data-display/Icon/Icon";

interface PlayPauseButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    PauseIcon: typeof Icon;
    PlayIcon: typeof Icon;
}

export class PlayPauseButton
    extends Lightning.Component<PlayPauseButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayPauseButtonTemplateSpec>
{
    _playing = true;
    static override _template(): Lightning.Component.Template<PlayPauseButtonTemplateSpec> {
        return {
            h: 40,
            w: 40,
            collision: true,
            PlayIcon: {
                w: 40,
                h: 40,
                type: Icon,
                icon: "play",
                iconWidth: 40,
                iconHeight: 40,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                alpha: 0.01
            },
            PauseIcon: {
                w: 40,
                h: 40,
                type: Icon,
                icon: "pause",
                iconWidth: 40,
                iconHeight: 40,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2
            }
        };
    }

    readonly PlayIcon = this.getByRef("PlayIcon")!;
    readonly PauseIcon = this.getByRef("PauseIcon")!;

    override _getFocused() {
        return this.PauseIcon;
    }

    override _focus() {}

    override _unfocus() {}

    _handleClick() {
        this.fireAncestors("$triggerVideoPlayPause");
    }

    triggeredPlayPause() {
        this._playing = !this._playing;

        this._setState(this._playing ? "" : "PauseState");
    }

    triggeredPlay() {
        this._playing = true;

        this._setState(this._playing ? "" : "PauseState");
    }

    triggeredPause() {
        this._playing = false;

        this._setState(this._playing ? "" : "PauseState");
    }

    setPlayState(isPlaying: boolean) {
        this._playing = isPlaying;

        this._setState(this._playing ? "" : "PauseState");
    }

    static override _states() {
        return [
            class PauseState extends this {
                override _getFocused() {
                    return this.PlayIcon;
                }

                override $enter() {
                    this.PlayIcon.patch({
                        alpha: 1
                    });
                    this.PauseIcon.patch({
                        alpha: 0.01
                    });
                }

                override $exit() {
                    this.PlayIcon.patch({
                        alpha: 0.01
                    });
                    this.PauseIcon.patch({
                        alpha: 1
                    });
                }
            }
        ];
    }
}
