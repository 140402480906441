import widgets from "../../lib/widgets";
import { getLiveTvChannels } from "../../api/queries";
import { isSubscriptionActive } from "../../lib/utils";
import { LiveChannels } from "./LiveChannels";

export default [
    {
        path: "live",
        component: LiveChannels,
        options: {
            clearHistory: true
        },
        on: (page: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    const initialChannels = await getLiveTvChannels(0);
                    page.data = initialChannels;
                    resolve();
                } catch (e) {
                    console.error("Error loading initial live channels:", e);
                    reject(e);
                }
            });
        },
        widgets: widgets,
        cache: 60 * 60 * 24,
        beforeNavigate: async () => {
            if (!isSubscriptionActive()) {
                return "hoteltv-login";
            } else {
                return true;
            }
        }
    }
];
