import { Lightning } from "@lightningjs/sdk";
import { MovieDetails } from "../components/MovieDetails/MovieDetails";

import { MovieCategory } from "../components/MovieCategory/MovieCategory";
import { MovieActions } from "../components/MovieActions/MovieActions";
import { TranslatableText } from "../components/TranslatableText/TranslatableText";
import { Icon } from "../components/Icon/Icon";
import { MovieDetailsModel } from "../lib/models";
import theme from "../lib/theme";
import { MovieList } from "../components/MovieList/MovieList";
// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import configs from "../lib/configs";
import { Button } from "../components/Button/Button";

interface SeriesTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Movie: {
            Top: {
                Category: typeof MovieCategory;
                Details: typeof MovieDetails;
                Buttons: typeof MovieActions;
            };
        };
        DetailsLabel: {
            Label: typeof TranslatableText;
            Icon: typeof Icon;
        };
        Seasons: typeof List;
        List: typeof MovieList;
    };
}

interface SeriesTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Series
    extends Lightning.Component<SeriesTemplateSpec, SeriesTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SeriesTemplateSpec>
{
    _selectedSeason = 0;
    _hasSeasons = false;
    _data!: MovieDetailsModel;

    static override _template(): Lightning.Component.Template<SeriesTemplateSpec> {
        return {
            collision: true,
            Page: {
                w: theme.layout.screenW,
                x: theme.menu.w,
                collision: true,
                Movie: {
                    collision: true,
                    Top: {
                        collision: true,
                        y: 0,
                        x: 0,
                        w: theme.banner.w,
                        h: theme.banner.h,
                        Details: {
                            type: MovieDetails
                        },
                        Buttons: {
                            collision: true,
                            x: theme.banner.padding,
                            y: theme.banner.h - (theme.banner.paddingY || theme.banner.padding) - 56,
                            // flexItem: { marginTop: 56 },
                            type: MovieActions
                        }
                    }
                },
                Seasons: {
                    type: List,
                    collision: true,
                    spacing: 16,
                    y: theme.banner.h + 32,
                    w: theme.layout.screenW - theme.banner.padding * 2 - theme.menu.w,
                    x: theme.banner.padding,
                    signals: { onIndexChanged: true }
                },
                List: {
                    type: MovieList,
                    collision: true,
                    h: (h: number) => h,
                    w: (w: number) => w,
                    y: theme.banner.h + (configs.modules.seasons ? 120 : 82)
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Seasons = this.Page.getByRef("Seasons")!;
    readonly List = this.Page.getByRef("List")!;
    readonly Movie = this.Page.getByRef("Movie")!;
    readonly Top = this.Movie.getByRef("Top")!;
    readonly Buttons = this.Top.getByRef("Buttons")!;

    readonly Details = this.Top.getByRef("Details")!;

    set data(movie: MovieDetailsModel) {
        window.selectedMovie = movie;

        this._selectedSeason = 0;

        this._data = movie;

        this.application.emit("updateSelectedMovie", window.selectedMovie);

        this.application.emit("clearBackground");
        this.application.emit("updateSelectedMovie", movie);

        this.Details.patch({
            data: movie
        });

        this.Buttons.patch({
            data: movie
        });

        this.List.resetItems(this._data?.items || this._data?.seasons[this._selectedSeason]?.episodes || []);

        if (configs.modules.seasons && movie.seasons) {
            this._hasSeasons = true;
            this.Seasons.items = movie.seasons.map((item, index) => ({
                type: Button,
                isActive: this._selectedSeason === index,
                useBorder: true,
                label: `Season ${item.seasonNumber}`,
                h: 56,
                w: 140,
                actionId: "season"
            }));
        } else {
            this._hasSeasons = false;
        }
    }

    $handleBtnClick() {
        if (this._selectedSeason === this.Seasons.index) return;

        this._selectedSeason = this.Seasons.index;

        this.Seasons.items.map((item: any, index: number) => {
            item.isActive = this._selectedSeason === index;
            return item;
        });

        this.List.resetItems(this._data.seasons[this._selectedSeason]!.episodes || []);
    }

    onIndexChanged({ index }: any) {
        if (this._hasSeasons) {
            window.selectedMovie = {
                ...this._data,
                releaseYear: this._data.seasons[index]?.releaseYear
            };
            this.application.emit("updateSelectedMovie", window.selectedMovie!);
        }
    }

    override _getFocused(): any {
        return this.List;
    }

    override _active() {
        if (window.seasonSelectedIndex) {
            this._selectedSeason = window.seasonSelectedIndex;
            this.List.resetItems(this._data.seasons[this._selectedSeason]!.episodes || []);
            this.Seasons.items.map((item: any, index: number) => {
                item.isActive = this._selectedSeason === index;
                return item;
            });

            this.Seasons.index = window.seasonSelectedIndex;

            this.onIndexChanged({
                index: window.seasonSelectedIndex
            });

            window.seasonSelectedIndex = undefined;
        }

        if (window.selectedIndex) {
            this.List.index = window.selectedIndex;
            window.selectedIndex = undefined;
        }
    }

    override _handleUp() {
        if (this._hasSeasons) {
            this._setState("SeasonState");
        } else {
            window.selectedMovie = this._data;
            this.application.emit("updateSelectedMovie", window.selectedMovie);

            this._setState("TopState");
        }
    }

    override _handleDown() {
        if (this._hasSeasons) {
            this._setState("SeasonState");
        } else {
            this._setState("");
        }
    }

    static override _states() {
        return [
            class TopState extends this {
                override _getFocused() {
                    return this.Buttons;
                }
            },

            class SeasonState extends this {
                override _getFocused() {
                    return this.Seasons;
                }

                override _handleUp() {
                    this.onIndexChanged({
                        index: this._selectedSeason
                    });

                    this._setState("TopState");
                }

                override _handleDown() {
                    this.onIndexChanged({
                        index: this._selectedSeason
                    });

                    this._setState("");
                }
            }
        ];
    }
}
