import { Colors, Lightning } from "@lightningjs/sdk";
import { Icon } from "../../../../../components/data-display/Icon/Icon";
import theme from "../../../../../lib/theme";

interface SettingButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    Icon: typeof Icon;
}

export class SettingButton
    extends Lightning.Component<SettingButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingButtonTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingButtonTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Icon: {
                collision: true,
                type: Icon,
                icon: "settings",
                iconWidth: 43,
                iconHeight: 43
            }
        };
    }

    readonly Icon = this.getByRef("Icon")!;

    override _focus() {
        this.Icon.patch({
            color: Colors(theme.color.primary).get()
        });
    }

    override _unfocus() {
        this.Icon.patch({
            color: Colors(theme.color.text.primary).get()
        });
    }

    override _handleEnter() {
        this.signal("handleClick");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.signal("setFocusToSettingIcon");
    }
}
