import { Colors, Img, Lightning, Utils } from "@lightningjs/sdk";
import { SubtitleTrack } from "bitmovin-player";
import { TranslatableText } from "../../../../../components/data-display/TranslatableText/TranslatableText";
import { getPlaybackSpeed, toggleSubtitle } from "../../../../../lib/video-player";
import theme from "../../../../../lib/theme";

interface SettingsPopupTemplateSpec extends Lightning.Component.TemplateSpec {
    Title: typeof TranslatableText;
    MenuItems: {
        children: MenuItemTemplateSpec[];
    };
}

export class SettingsPopup
    extends Lightning.Component<SettingsPopupTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsPopupTemplateSpec>
{
    private _currentMenu: string = "main";
    private _subtitleMenuItems: {
        type: typeof MenuItem;
        label: string;
        value: string;
        menuSignal: string;
        icon: any;
        arrow: any;
        hide: boolean;
        data?: SubtitleTrack;
    }[] = [];
    private _subtitlePreference: string = "";
    _speedPreference: number = getPlaybackSpeed();

    _index = 0;

    readonly Title = this.getByRef("Title")!;
    readonly MenuItems = this.getByRef("MenuItems")!;

    private _setSubtitlePreference(lang: string) {
        localStorage.setItem("preferredSubtitle", lang);
        console.log(`Preferred subtitle set to: ${lang}`);
        this._subtitlePreference = lang;
    }

    static override _template(): Lightning.Component.Template<SettingsPopupTemplateSpec> {
        return {
            x: theme.layout.w,
            y: 0,
            w: 643,
            h: theme.layout.h,
            rect: true,
            color: Colors(theme.color.bg.primary).get(),
            alpha: 0,
            zIndex: 20,
            collision: true,
            Title: {
                y: 52,
                x: 72,
                w: 500,
                h: 50,
                type: TranslatableText,
                text: {
                    fontSize: 38,
                    lineHeight: 40,
                    fontFace: "SemiBold",
                    letterSpacing: 0.44,
                    textAlign: "left",
                    textColor: Colors(theme.color.text.primary).get()
                }
            },
            MenuItems: {
                y: 150,
                x: 72,
                rect: true,
                collision: true,
                flex: { direction: "column", alignItems: "stretch" },
                color: Colors(theme.color.bg.primary).get(),
                children: []
            }
        };
    }

    override _active() {
        this.setSmooth("x", theme.layout.w - this.w);
        this._updateMenuItems();
    }

    override _inactive() {
        this.setSmooth("x", theme.layout.w); // Slide-out effect
    }

    override _getFocused(): MenuItem | undefined {
        return this.MenuItems!.children[this._index] as MenuItem;
    }

    override _handleUp() {
        if (this._index > 0) {
            this._index--;
            this._refocus();
        }
    }

    override _handleDown() {
        if (this._index < this.MenuItems!.children.length - 1) {
            this._index++;
            this._refocus();
        }
    }

    override _handleEnter() {
        const focusedItem = this.MenuItems!.children[this._index] as MenuItem;

        const label = focusedItem.tag("ContentLeft.Label")?.text?.text || "undefined";
        const value = focusedItem.tag("ContentRight.Value")?.text?.text || "undefined";

        if (focusedItem && focusedItem.menuSignal) {
            switch (focusedItem.menuSignal) {
                case "onSubtitleClick":
                    this.currentMenu = "subtitles";
                    break;
                case "onSubtitleSelect":
                    this._setSubtitlePreference(value == "off" ? "" : value);
                    toggleSubtitle(value);
                    if (value == "off") {
                        this.fireAncestors("$clearSubtitleText");
                    }
                    this.fireAncestors("$hideSettingsPopup");
                    break;
                case "onPlaybackClick":
                    this.currentMenu = "playback";
                    break;
                case "onPlaybackSelect":
                    const speed = parseFloat(value);
                    if (!isNaN(speed)) {
                        this._speedPreference = speed;
                        this.fireAncestors("$setPlaybackSpeed", speed);
                    }
                    break;
                default:
                    this.signal(focusedItem.menuSignal);
                    break;
            }
        }
    }

    override _handleBack() {
        if (["subtitles", "playback"].includes(this._currentMenu)) {
            this.currentMenu = "main";
        } else {
            this.fireAncestors("$hideSettingsPopup");
        }
    }

    set currentMenu(value: string) {
        this._currentMenu = value;
        this._updateMenuItems();
    }

    private _updateMenuItems() {
        if (!this.MenuItems) {
            return;
        }
        let children: {
            type: any;
            label: string;
            value: string;
            menuSignal: string;
            icon: any;
            arrow: any;
            hide: boolean;
            signals: {
                onHover: string;
            };
        }[] = [];

        if (this._currentMenu === "main") {
            if (this.Title && this.Title.text) {
                this.Title.patch({
                    key: "settings.popup.settingMenuTitle"
                });
            }

            children = [
                {
                    type: MenuItem,
                    label: "settings.popup.subtitleMenuTitle",
                    value: this._subtitlePreference ? "On" : "Off",
                    menuSignal: "onSubtitleClick",
                    icon: "/icons/subtitle.png",
                    arrow: "/icons/chevron-right.png",
                    hide: false,
                    signals: {
                        onHover: "_handleHover"
                    }
                },
                {
                    type: MenuItem,
                    label: "settings.popup.playbackSubMenuTitle",
                    value: `${this._speedPreference}x`,
                    menuSignal: "onPlaybackClick",
                    icon: "/icons/playback.png",
                    arrow: "/icons/chevron-right.png",
                    hide: false,
                    signals: {
                        onHover: "_handleHover"
                    }
                }
            ];
            this._index = 0;
        } else if (this._currentMenu === "subtitles") {
            if (this.Title && this.Title.text) {
                this.Title.patch({
                    key: "settings.popup.subtitleMenuTitle"
                });
            }

            this._index = this._subtitlePreference
                ? this._subtitleMenuItems.findIndex((s) => s.value === this._subtitlePreference)
                : 0;

            children = this._subtitleMenuItems.map((s, i) => ({
                ...s,
                selected: this._index === i,
                signals: {
                    onHover: "_handleHover"
                }
            }));
        } else if (this._currentMenu === "playback") {
            if (this.Title && this.Title.text) {
                this.Title.patch({
                    key: "settings.popup.playbackMenuTitle"
                });
            }
            const speeds = ["0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2"];
            this._index = speeds.findIndex((s) => +s === this._speedPreference);
            children = speeds.map((speed) => {
                return {
                    type: MenuItem,
                    label: `${speed}x`,
                    value: speed,
                    menuSignal: "onPlaybackSelect",
                    icon: null,
                    arrow: null,
                    hide: true,
                    selected: this._speedPreference === +speed,
                    signals: {
                        onHover: "_handleHover"
                    }
                };
            });
        }
        this.MenuItems.patch({ children });
        this._refocus();
    }

    setAvailableSubtitles(subtitles: SubtitleTrack[]) {
        let subtitleMenuItems = [
            {
                type: MenuItem,
                label: "settings.popup.menuItemOff",
                value: "off",
                menuSignal: "onSubtitleSelect",
                icon: null,
                arrow: null,
                hide: true,
                selected: this._subtitlePreference == "" || subtitles.length === 0
            }
        ];

        subtitleMenuItems = subtitleMenuItems.concat(
            subtitles.map((sub) => ({
                type: MenuItem,
                label: sub.label,
                value: sub.lang,
                menuSignal: "onSubtitleSelect",
                icon: null,
                arrow: null,
                hide: true,
                data: JSON.parse(JSON.stringify(sub)),
                selected: false
            }))
        );
        this._subtitleMenuItems = subtitleMenuItems;

        this._updateMenuItems();
    }

    setSubtitlePreference(lang: string) {
        this._subtitlePreference = lang;
    }

    _handleClick(e: any) {
        if (this._handleEnter) {
            this._handleEnter();
        }
    }

    _handleHover(hoveredItem: MenuItem) {
        const index = this.MenuItems.children.findIndex((item) => item === hoveredItem);

        if (index !== -1 && index !== this._index) {
            this._index = index;
            this._refocus();
        }
    }
}

interface MenuItemTemplateSpec extends Lightning.Component.TemplateSpec {
    ContentLeft: {
        Icon: object;
        Label: typeof TranslatableText;
    };
    ContentRight: {
        Value: object;
        Arrow: object;
    };
}

class MenuItem
    extends Lightning.Component<MenuItemTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MenuItemTemplateSpec>
{
    menuSignal?: string;
    private _hide: boolean = false;
    static override _template(): Lightning.Component.Template<MenuItemTemplateSpec> {
        return {
            w: 500,
            h: 72,
            zIndex: 20,
            rect: true,
            flex: {
                direction: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 20,
                paddingRight: 20
            },
            flexItem: { grow: 1, marginBottom: 16 },
            color: Colors(theme.color.bg.primary).get(),
            collision: true,
            ContentLeft: {
                w: 234,
                h: 40,
                collision: true,
                flex: {
                    direction: "row",
                    alignItems: "center"
                },
                Icon: {
                    w: 32,
                    h: 32,
                    collision: true,
                    texture: null
                },
                Label: {
                    flexItem: {
                        marginLeft: 16
                    },
                    y: 2,
                    type: TranslatableText,
                    collision: true,
                    text: {
                        text: "",
                        fontSize: 28,
                        lineHeight: 26,
                        fontFace: "Medium",
                        textAlign: "left",
                        textColor: Colors(theme.color.text.primary).get()
                    }
                }
            },
            ContentRight: {
                w: 234,
                h: 40,
                flexItem: { grow: 0 },
                flex: {
                    direction: "row",
                    alignItems: "center",
                    justifyContent: "flex-end"
                },
                collision: true,
                Value: {
                    y: 3,
                    collision: true,
                    text: {
                        text: "",
                        fontSize: 28,
                        fontFace: "Medium",
                        textAlign: "right",
                        textColor: Colors(theme.color.text.tertiary).get()
                    }
                },
                Arrow: {
                    flexItem: {
                        marginLeft: 16
                    },
                    collision: true,
                    w: 30,
                    h: 30,
                    texture: null
                }
            }
        };
    }

    set label(value: string) {
        this.tag("ContentLeft.Label")!.patch({
            key: value
        });
    }

    set value(value: string) {
        this.tag("ContentRight.Value")!.text = value;
    }

    set icon(value: string) {
        if (value) {
            this.tag("ContentLeft.Icon")!.texture = Img(Utils.asset(value)).contain(32, 32);
        }
    }

    set arrow(value: string) {
        if (value) {
            this.tag("ContentRight.Arrow")!.texture = Img(Utils.asset(value)).contain(32, 32);
        }
    }

    set hide(value: boolean) {
        this._hide = value;
        this.tag("ContentRight.Value")!.alpha = value ? 0 : 1;
    }

    get hide(): boolean {
        return this._hide;
    }

    override _focus() {
        this.patch({
            smooth: {
                color: Colors(theme.color.primary).get()
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: { color: Colors(theme.color.bg.primary).get() }
        });
    }

    set selected(value: boolean) {
        this.patch({
            ContentLeft: {
                Icon: {
                    texture: value ? Img(Utils.asset("/icons/check.png")).contain(40, 40) : null
                }
            }
        });
    }

    _handleHover(e: any) {
        this.signal("onHover", this);
    }
}
