export const contentGroupItemSelectFragment = `
	fragment ContentGroupItemSelectFragment on ContentGroup {
	  items {
		... on TvSeries {
		  id
		  title
		  coverUrl
		  backdropUrl
		  description
		  releaseYear
		  __typename
		}
		... on Collection {
		  id
		  title
		  coverUrl
		  backdropUrl
		  description
		  releaseYear
		  __typename
		}
		... on Season {
		  id
		  title
		  coverUrl
		  backdropUrl
		  description
		  releaseYear
		  __typename
		}
		... on Movie {
		  id
		  title
		  coverUrl
		  backdropUrl
		  description
		  releaseYear
		  __typename
		}

		__typename
	  }
	}
`;

export const categoryItemsSelectFragment = `
	fragment CategoryItemsSelect on Category {
	  items (limit: $limit, offset: $offset) {
		... on TvSeries {
		  id
		  title
		  __typename
		}
		__typename
	  }
	}
`;

export const contentSearchFragment = `
    fragment ContentSearchFragment on ContentItem {
    ... on TvSeries {
          id
          title
          coverUrl
          __typename
        }
    __typename
    }
`;

export const continueWatchingItemsSelectFragment = `
	fragment ContinueWatchingItemsSelect on ContinueWatching {
	  items {
		... on Episode {
		  id
		  title
		  progress {
			current
		  }
		  __typename
		}
		__typename
	  }
	}
`;

export const contentListItemSelectFragment = `
	fragment ContentItemsSelect on ContentItem {
	  ... on TvSeries {
		id
		title
		coverUrl
		backdropUrl
		description
		releaseYear
		isFree
		__typename
	  }
      ... on Season {
        id
        title
        description
        coverUrl
        backdropUrl
        __typename
      }
	  ... on Collection {
		id
		title
		description
		backdropUrl
		coverUrl
		isFree
		__typename
	  }
	  ... on Movie {
		id
		title
		coverUrl
		backdropUrl
		description
		duration
		releaseYear
		isFree
		progress {
          current
        }
		__typename
	  }
	  ... on LiveStream {
	  id
		title
		coverUrl
		backdropUrl
		description
		isLiveNow
		isFree
		__typename
	  }
	  ... on Episode {
		id
		title
		coverUrl
		backdropUrl
		description
		duration
		isFree
		progress {
          current
        }
		season{
            series {
              id
              title
            }
        }
		__typename
	  }
	  __typename
	}
`;

export const userFragment = `
fragment UserData on User {
    id
    username
    subscribed
    token
    isLoggedIn
    currentPeriodEnd
    userManagement
    isPromoted
}`;
