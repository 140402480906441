import { Grid } from "@lightningjs/ui";
import { Colors, Language, Lightning, Registry, Router } from "@lightningjs/sdk";
import { getMediaPosterProps, redirectToSelectedMovie } from "../../../lib/utils";
import { TranslatableText } from "../TranslatableText/TranslatableText";
import { MediaType, MediaModel } from "../../../lib/models";
import theme from "../../../lib/theme";
import { getContentByCategory, getContinueWatching, getTrending } from "../../../api/queries";
import { MediaPosterWithLabel } from "../MediaPosterWithLabel/MediaPosterWithLabel";
import { SecondaryButton } from "../../input/Button/SecondaryButton";

interface MediaListTemplateSpec extends Lightning.Component.TemplateSpec {
    isContinueWatching: boolean;
    isTrending: boolean;

    isFullScreen: boolean;

    categoryId: string;

    items: MediaModel[];
    categoryKey: string;
    navigateOnSelect: boolean;

    mediaTypeId: string | undefined;

    offsetX: number;
    Top: {
        Category: typeof TranslatableText;
        Button: typeof SecondaryButton;
    };
    ListContainer: {
        Placeholders: object;
        List: typeof Grid;
    };
}

export class MediaList
    extends Lightning.Component<MediaListTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MediaListTemplateSpec>
{
    _itemsLength = 0;
    // _categoryTranslations!: TranslationModel[];
    _categoryId?: string;
    _categoryKey!: string;

    _navigateOnSelect = true;
    _isContinueWatching = false;
    _isTrending = false;

    _lang = Language.get();

    _mediaTypeId: string | undefined;
    _isFullScreen = false;

    static override _template(): Lightning.Component.Template<MediaListTemplateSpec> {
        return {
            alpha: 0.01,
            collision: true,
            x: theme.banner.padding.x,
            Top: {
                w: (w) => w - theme.banner.padding.x,
                flex: {
                    justifyContent: "space-between",
                    alignItems: "center"
                },
                Category: {
                    type: TranslatableText,
                    text: {
                        fontSize: 28,
                        fontFace: "Secondary-SemiBold",
                        textColor: Colors(theme.color.text.primary).get()
                    }
                },
                Button: {
                    w: 120,
                    type: SecondaryButton,
                    label: "general.viewAll",
                    iconPosition: "right",
                    icon: "chevron-right",
                    alpha: 0,
                    signals: {
                        handleHover: "_handleHover",
                        handleClick: "_handleClick"
                    }
                }
            },
            ListContainer: {
                h: (h) => h,
                w: (w) => w + 32,
                x: -32,
                y: 16,
                collision: true,
                Placeholders: {
                    alpha: 0.01,
                    y: 50,
                    h: (h) => h,
                    w: (w) => w,
                    flex: {
                        direction: "row"
                    }
                },
                List: {
                    y: 16,
                    x: 32,
                    alpha: 0,
                    collision: true,
                    type: Grid,
                    // rows: 1,
                    // direction: "column",
                    // columns: 8,
                    direction: "row",
                    spacing: 42,
                    // h: 1080,
                    w: 1760,
                    enableRequests: true,
                    requestThreshold: 12,
                    signals: { onRequestItems: true },
                    gcThreshold: 15,
                    scroll: {
                        after: 2
                    },
                    scrollTransition: {
                        duration: 0
                    }
                }
            }
        };
    }
    readonly Top = this.getByRef("Top")!;
    readonly Category = this.Top.getByRef("Category")!;
    readonly CategoryButton = this.Top.getByRef("Button")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly Placeholders = this.ListContainer.getByRef("Placeholders")!;
    readonly List = this.ListContainer.getByRef("List")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    override _handleDown(): boolean | undefined {
        if (!this._isFullScreen) return false;

        console.log(this.List.index, this.List.length);
        if (this.List.index < this.List.length - 1) {
            this.List.last();
        } else {
            return false;
        }
    }

    override _setup() {
        this.Placeholders.children = Array(10).fill({
            ...this._getMediaPosterProps(),
            flexItem: {
                marginRight: 30
            }
        });
    }

    _clearList() {
        this.List.clear();
    }

    override _active() {
        this.fadeIn();

        if (this.List.items.length === 0) {
            this._reloadItems();
        }
    }

    override _handleUp(): boolean | undefined {
        if (!this._categoryId || this._isFullScreen) return false;

        this._setState("CategoryButton");
    }

    override _handleBack() {
        if (this.List.index > 0) {
            this.List.index = 0;
            return true;
        }

        return false;
    }

    async _reloadItems() {
        this.resetItems(await this.onRequestItems());
    }

    _setItems(data: MediaModel[]) {
        this._itemsLength = data.length;

        if (data.length) {
            const items = data.map((item: any) => {
                return item.data
                    ? {
                          ...this._getMediaPosterProps(item.data.__typename),
                          data: item.data
                      }
                    : {
                          ...this._getMediaPosterProps(item.__typename),
                          data: item
                      };
            });

            this.List.patch({
                spacing: items[0]!.typename === "Episode" ? theme.episodeItem.gap : theme.mediaItem.gap
            });

            this.List.add(items);

            this._enableAnimation();

            for (const wrapper of this.List.children) {
                wrapper?.patch({
                    collision: true
                });
            }

            this.List.patch({
                smooth: {
                    alpha: 1
                }
            });

            this.Placeholders.patch({
                smooth: {
                    alpha: 0.01
                }
            });
        } else {
            // this.List.clear();
        }
    }

    _update() {
        this.Placeholders.patch({
            alpha: this.List.items.length ? 0.01 : 1
        });

        this.Category.patch({
            backupValue: this._categoryKey,
            key: this._categoryKey ? `category.${this._categoryKey?.toLowerCase()}` : ""
        });

        this.ListContainer.patch({ y: 50 });
    }

    _getMediaPosterProps(type?: MediaType) {
        const mediaProps = getMediaPosterProps(type);
        return {
            type: MediaPosterWithLabel,
            typename: type,
            ...mediaProps,
            // offset with titles
            h: mediaProps.h + 106,
            isContinueWatching: this._isContinueWatching
        };
    }

    _enableAnimation() {
        Registry.setTimeout(() => {
            this.List.patch({
                scrollTransition: {
                    duration: 0.4,
                    timingFunction: "linear"
                }
            });
        }, 400);
    }

    addItems(data: MediaModel[]) {
        if (!data.length) return;

        this.List.add(
            data.map((item: MediaModel) => ({
                ...this._getMediaPosterProps(item.__typename),
                data: item
            }))
        );

        // for search, since pagination is loaded for multiple lists on search
        this.List.patch({
            enableRequests: true
        });
    }

    resetItems(data: any) {
        if (!data.length) return;

        if (data[0].id) {
            data = data.map((item: MediaModel) => ({
                ...this._getMediaPosterProps(item.__typename),
                data: item
            }));
        }

        this.List.reload(data);

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }

        this.List.patch({
            smooth: {
                alpha: 1
            }
        });

        this.Placeholders.patch({
            smooth: {
                alpha: 0.01
            }
        });
        this._enableAnimation();
    }

    async onRequestItems() {
        const data = this._isContinueWatching
            ? this.List.items.length === 0
                ? await getContinueWatching()
                : []
            : this._isTrending
              ? this.List.items.length === 0
                  ? await getTrending()
                  : []
              : this._categoryId
                ? (await getContentByCategory(this._categoryId, this.List.items.length)).items
                : [];

        this.fireAncestors("$categoryMoviesLoaded", { id: this._categoryId!, movies: data });

        return data.map((item: MediaModel) => ({
            ...this._getMediaPosterProps(item.__typename),
            data: item
        }));
    }

    fadeIn() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    fadeOut() {
        this.patch({
            smooth: {
                alpha: 0.01
            }
        });
    }

    clear() {
        this.List.clear();
    }

    $movieClicked(movie: MediaModel) {
        const listIndex = this.List.items.findIndex((item: any) => {
            return item?.data?.id === movie.id;
        });

        if (listIndex > -1 && listIndex !== this.List.index) {
            this.List.setIndex(listIndex);
        }

        if (!this.hasFocus()) {
            // @ts-expect-error no types available yet,
            this.fireAncestors("$categorySelected", this.parent.componentIndex);
        }
    }

    $movieSelected(movie: MediaModel) {
        if (this._navigateOnSelect) {
            redirectToSelectedMovie({
                id: movie.id,
                type: movie.__typename,
                isFree: movie.isFree,
                movie: movie
            });
        } else {
            this.fireAncestors("$movieSelected", movie);
        }
    }

    static override _states() {
        return [
            class CategoryButton extends this {
                override _getFocused() {
                    return this.CategoryButton;
                }

                override _handleUp() {
                    return false;
                }

                override _handleDown() {
                    this._setState("");
                    return true;
                }

                override _handleEnter() {
                    Router.navigate(`category/${this._categoryId}`);
                }
            }
        ];
    }

    static get height() {
        // todo dynamic, 158 is category height
        return theme.mediaItem.h + 158;
    }

    static get width() {
        return theme.layout.w - theme.menu.w - theme.banner.padding.x;
    }

    get index() {
        return this.List.index;
    }

    set index(value: number) {
        this.List.setIndex(value);
    }

    set offsetX(value: number) {
        this.Category.patch({ x: value });
        this.ListContainer.patch({ x: value });
    }

    set navigateOnSelect(value: boolean) {
        this._navigateOnSelect = value;
    }

    set categoryId(value: string) {
        this.CategoryButton.patch({
            alpha: !!value ? 1 : 0
        });

        this._categoryId = value;
    }

    get categoryId() {
        return this._categoryId!;
    }

    set isContinueWatching(value: boolean) {
        this._isContinueWatching = value;
    }

    get isContinueWatching(): boolean {
        return this._isContinueWatching;
    }

    set isTrending(value: boolean) {
        this._isTrending = value;
    }

    get isTrending(): boolean {
        return this._isTrending;
    }

    set items(value: MediaModel[]) {
        if (this._isContinueWatching && window.reloadContinueWatching) {
            window.reloadContinueWatching = false;

            this._reloadItems();
        } else {
            this._setItems(value);
        }
    }

    get items() {
        return this.List.items;
    }

    set categoryKey(value: string) {
        this._categoryKey = value;

        this._update();
    }

    get categoryKey() {
        return this._categoryKey;
    }

    get lastIndex() {
        return this.List.index;
    }

    set lastIndex(value: number) {
        if (this.List.items.length) {
            this.List.index = value;

            this._enableAnimation();
        }
    }

    get lang() {
        return this._lang;
    }

    set lang(value) {
        this._lang = value;
    }

    set mediaTypeId(value: string | undefined) {
        this._mediaTypeId = value;
    }

    get mediaTypeId(): string | undefined {
        return this._mediaTypeId;
    }

    set isFullScreen(value: boolean) {
        this._isFullScreen = value;

        if (value) {
            this.CategoryButton.patch({ alpha: 0 });
        }

        this.ListContainer.patch({ clipping: value });

        this.List.patch({
            direction: "column",
            h: 1080 - 80 - theme.banner.padding.y * 2,
            w: theme.layout.w - theme.menu.w - theme.banner.padding.x * 2
        });
    }
}
