import { Colors, Lightning, Registry, Router } from "@lightningjs/sdk";
import { PAGES } from "../../lib/utils";
import { Keyboard, InputField, List } from "@lightningjs/ui";
import { KeyboardKey } from "../../components/input/KeyboardKey/KeyboardKey";
import { MediaList } from "../../components/data-display/MediaList/MediaList";
import { search } from "../../api/queries";
import { LoadingCircle } from "../../components/feedback/LoadingCircle/LoadingCircle";
import { TranslatableText } from "../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../lib/theme";
import {MediaModel} from "../../lib/models";

interface SearchTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        InputContainer: {
            Wrapper: {
                Input: typeof InputField;
            };
        };
        KeyboardContainer: {
            Keyboard: typeof Keyboard;
        };
        ListWrapper: {
            List: typeof List;
        };
        Loading: typeof LoadingCircle;
        NotFound: {
            Text: typeof TranslatableText;
        };
    };
}

interface SearchTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

const keyboardConfig = {
    layouts: {
        ABC: [
            ["A", "B", "C", "D", "E", "F"],
            ["G", "H", "I", "J", "K", "L"],
            ["M", "N", "O", "P", "Q", "R"],
            ["S", "T", "U", "V", "W", "X"],
            ["Y", "Z", "1", "2", "3", "4"],
            ["5", "6", "7", "8", "9", "0"],
            ["Clear", "Space", "Backspace"]
        ]
    },
    buttonTypes: {
        default: {
            type: KeyboardKey,
            label: {
                text: {
                    fontSize: 32
                }
            }
        },
        Clear: {
            type: KeyboardKey,
            w: 188,
            icon: "clear"
        },
        Space: {
            type: KeyboardKey,
            w: 188,
            icon: "space"
        },
        Backspace: {
            type: KeyboardKey,
            w: 188,
            icon: "backspace"
        }
    },
    styling: {
        align: "center", //aligns the rows when the width of the Keyboard component is bigger than 0 (zero).
        horizontalSpacing: 4, //spacing between the keys
        verticalSpacing: 4 //spacing between the rows
    }
};

export class Search
    extends Lightning.Component<SearchTemplateSpec, SearchTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SearchTemplateSpec>
{
    _selectedMovie: undefined | MediaModel;
    _timeout: undefined | number;
    _abortController!: AbortController;
    _isSearchState = false;
    _isSearchLoading = false;

    _listIndex? = 0;

    static override _template(): Lightning.Component.Template<SearchTemplateSpec> {
        return {
            collision: true,
            Page: {
                collision: true,
                x: theme.menu.w,
                InputContainer: {
                    y: 70,
                    x: theme.layout.content.x,
                    w: theme.keyboard.input.w,
                    h: 66,
                    clipping: true,
                    texture: Lightning.Tools.getRoundRect(
                        theme.keyboard.input.w,
                        66,
                        6,
                        0,
                        undefined,
                        true,
                        Colors(theme.keyboard.input.bg.color).get()
                    ),
                    Wrapper: {
                        x: 37,
                        y: 6,
                        w: (w) => w,
                        h: (h) => h,
                        clipping: true,
                        Input: {
                            y: 4,
                            w: theme.keyboard.input.w - 37 * 2,
                            type: InputField,
                            inputText: {
                                fontSize: 32,
                                lineHeight: 36,
                                textColor: Colors(theme.color.text.primary).get()
                            },
                            // @ts-expect-error types
                            cursor: {
                                h: 48,
                                w: 2,
                                color: Colors(theme.color.text.primary).get()
                            },
                            maxLabelWidth: 570 - 37 * 2,
                            labelPositionStatic: false
                        }
                    }
                },
                KeyboardContainer: {
                    collision: true,
                    y: 152,
                    x: theme.layout.content.x,
                    rect: true,
                    // color: Colors(theme.keyboard.key.text.color).get(),
                    w: 572,
                    h: 669,
                    texture: Lightning.Tools.getRoundRect(
                        theme.keyboard.input.w,
                        669,
                        6,
                        0,
                        undefined,
                        true,
                        Colors(theme.keyboard.key.bg.color).get()
                    ),
                    // shader: { type: Lightning.shaders.RoundedRectangle, radius: 6 },
                    // rect: true,
                    // w: theme.keyboard.w + 1,
                    // h: 680,
                    Keyboard: {
                        collision: true,
                        type: Keyboard,
                        config: keyboardConfig,
                        currentLayout: "ABC",
                        maxCharacters: 35,
                        signals: { onInputChanged: true, onSpace: true }
                    }
                },
                ListWrapper: {
                    collision: true,
                    clipping: true,
                    y: 123,
                    x: theme.layout.content.x + theme.keyboard.w + 52,
                    w: theme.layout.w - (theme.layout.content.x + theme.keyboard.w + 62 + theme.menu.w),
                    h: theme.layout.h - 123,
                    List: {
                        type: List,
                        spacing: 42,
                        x: 22,
                        w:
                            theme.layout.w -
                            (theme.layout.content.x + theme.keyboard.w + 62 + theme.menu.w),
                        h: 900,
                        direction: "column"
                    }
                },
                Loading: {
                    type: LoadingCircle,
                    alpha: 0,
                    y: 0,
                    x: theme.layout.content.x + theme.keyboard.w + 62,
                    w: theme.layout.w - (theme.layout.content.x + theme.keyboard.w + 62 + theme.menu.w),
                    h: theme.layout.h - 123
                },
                NotFound: {
                    alpha: 0,
                    y: 0,
                    x: theme.layout.content.x + theme.keyboard.w + 62,
                    w: theme.layout.w - (theme.layout.content.x + theme.keyboard.w + 62 + theme.menu.w),
                    h: theme.layout.h - 123,
                    Text: {
                        mount: 0.5,
                        x: (w) => w / 2,
                        y: (h) => h / 2,
                        type: TranslatableText,
                        key: "search.empty",
                        text: {
                            textAlign: "center",
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly List = this.Page.getByRef("ListWrapper")!.getByRef("List")!;
    readonly InputContainer = this.Page.getByRef("InputContainer")!;
    readonly InputContainerWrapper = this.InputContainer.getByRef("Wrapper")!;
    readonly Input = this.InputContainerWrapper.getByRef("Input")!;

    readonly KeyboardContainer = this.Page.getByRef("KeyboardContainer")!;
    readonly Keyboard = this.KeyboardContainer.getByRef("Keyboard")!;

    readonly Loading = this.Page.getByRef("Loading")!;
    readonly NotFound = this.Page.getByRef("NotFound")!;

    override _setup() {
        this._abortController = new AbortController();

        this.Keyboard.inputField(this.Input);

        this._setState("SearchState");
    }

    override historyState(params?: { index: number }) {
        if (params?.index) {
            this._listIndex = params.index;
        } else {
            return {
                index: this._listIndex
            };
        }
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Keyboard;
    }

    override _active() {
        this.application.emit("clearBackground");
        this.application.emit("setActivePage", PAGES.search.name);

        for (const item of this.Keyboard.children) {
            item.patch({
                collision: true
            });

            for (const subItem of item.children) {
                subItem.patch({
                    collision: true
                });
            }
        }
    }

    override _inactive() {
        this._cancelSearch();
        // this._finishSearch();

        // this.List.clear();

        this.stage.gc();
    }

    _cancelSearch() {
        if (this._isSearchLoading) {
            this._abortController.abort();

            this._abortController = new AbortController();
        }

        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = undefined;
        }
    }

    _finishSearch(data: undefined | MediaModel[] = undefined, reload = true) {
        this._isSearchState = false;

        if (data) {
            if (reload) {
                this.List.reload(
                    data.map((item) => ({
                        type: MediaList,
                        mediaTypeId: item.id,
                        items: item.items,
                        x: 0,
                        navigateOnSelect: true,
                        h: item.id === "channels" ? 240 : undefined
                    }))
                );
            } else {
                this.List.items.forEach((item, index) => {
                    const rowData = data.find((searchItem) => searchItem.id === item.mediaTypeId);
                    if (rowData) {
                        item.addItems(rowData.items);
                    }
                });
            }

            if (this._listIndex) {
                this.List.index = this._listIndex;
                this._listIndex = undefined;
            }
        }

        this._toggleLoader();
    }

    _toggleLoader() {
        if (this._isSearchState) {
            this._setState("SearchState");
        }

        this.Loading.patch({
            smooth: {
                alpha: this._isSearchState ? 1 : 0
            }
        });

        const listHasItems = this.List.items.length > 0;

        this.List.patch({
            smooth: {
                alpha: this._isSearchState || !listHasItems ? 0 : 1
            }
        });

        this.NotFound.patch({
            smooth: {
                alpha: this._isSearchState || listHasItems ? 0 : 1
            }
        });
    }

    onInputChanged({ input }: { input: string }) {
        this._cancelSearch();

        this._timeout = Registry.setTimeout(() => {
            this._timeout = undefined;
            this._isSearchState = true;
            this._toggleLoader();

            (async () => {
                this.List.clear();

                this._isSearchLoading = true;

                if (!input.length) {
                    this.NotFound.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                    this._finishSearch();
                } else {
                    try {
                        const data = await search(
                            {
                                query: input,
                                offset: 0,
                                limit: 20
                            },
                            this._abortController.signal
                        );
                        this._finishSearch(data);
                    } catch (e: any) {
                        if (!e.aborted && e.name !== "AbortError") {
                            this._finishSearch();
                        }
                    }
                }

                this._isSearchLoading = false;
            })();
        }, 660);
    }

    async $categoryMoviesLoaded() {
        console.log(
            "this.List.items.map((item) => item.items.length",
            this.List.items.map((item) => item.items.length)
        );
        const data = await search(
            {
                query: this.Input.input,
                limit: 20,
                offset: Math.max(...this.List.items.map((item) => item.items.length)!)
            },
            this._abortController.signal
        );
        this._finishSearch(data, false);
    }

    $categorySelected() {
        this._setState("ResultsState");
    }

    $focusKey(key: string) {
        this.Keyboard.focus(key);
        this._setState("SearchState");

        window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 13, code: "13" }));
    }

    static override _states() {
        return [
            class SearchState extends this {
                override _handleRight() {
                    if (this.List.items.length > 0 && !this._isSearchLoading) {
                        this._setState("ResultsState");
                    }
                }
            },
            class ResultsState extends this {
                override _getFocused() {
                    return this.List;
                }

                override _handleLeft() {
                    this._setState("SearchState");
                }

                $movieSelected(data: MediaModel) {
                    this._listIndex = this.List.index;
                    this._selectedMovie = data;
                }
            }
        ];
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;

        Router.go(Router.getHistory().length * -1);

        return true;
    }
}
