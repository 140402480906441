import { Lightning, VideoPlayer } from "@lightningjs/sdk";
import { ChannelItemModel } from "../../../../lib/models";
import { Loading } from "../../../../components/feedback/Loading/Loading";
import theme from "../../../../lib/theme";
import { loader, unloader } from "../../../../lib/video-player";

interface LiveVideoPlayerTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof Loading;
}

export class LiveVideoPlayer
    extends Lightning.Component<LiveVideoPlayerTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<LiveVideoPlayerTemplateSpec>
{
    private _channel: ChannelItemModel | null = null;

    static override _template(): Lightning.Component.Template<LiveVideoPlayerTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            Loading: {
                type: Loading
            }
        };
    }

    readonly Loading = this.getByRef("Loading")!;

    override _firstActive() {
        VideoPlayer.consumer(this);
        VideoPlayer.position(0, 0);
    }

    override _active() {
        this.application.emit("hideBackground");
    }

    override _inactive() {
        this.application.emit("showBackground");
        this._destroyPlayer();
    }

    override _handleEnter() {
        VideoPlayer.play();
    }

    _initPlayer() {
        if (!this._channel) return;
        this.application.emit("showBackground");

        this._loading = true;

        this._destroyPlayer();

        VideoPlayer.loader(loader);
        VideoPlayer.unloader(unloader);

        if (this._channel.url) {
            VideoPlayer.open(this._channel.url);
        } else {
            // todo: show error or black screen
            this._loading = false;
        }
    }

    _destroyPlayer() {
        VideoPlayer.close();
        VideoPlayer.clear();
    }

    isChannelPlaying(channel: ChannelItemModel): boolean {
        return this._channel?.id === channel.id;
    }

    $videoPlayerPlaying() {
        this.application.emit("hideBackground");

        this._loading = false;
    }

    $videoPlayerCanPlay() {
        this.application.emit("hideBackground");

        this._loading = false;
    }

    $videoPlayerError(event: string) {
        console.error("Video player error:", event);
        this._loading = false;
    }

    set channel(channel: ChannelItemModel) {
        this._channel = channel;
        this._initPlayer();
    }

    set _loading(value: boolean) {
        if (this.Loading) {
            this.Loading.visible = value;
        }
    }
}
