import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../../data-display/TranslatableText/TranslatableText";
import { Icon } from "../../data-display/Icon/Icon";
import theme from "../../../lib/theme";

interface ButtonTemplateSpec extends Lightning.Component.TemplateSpec {
    useBorder: boolean;
    isActive: boolean;
    enterAction: any | undefined;
    actionId: string | undefined;
    label: string;
    icon: string;
    hasActive: boolean;
    iconWidth: number;
    disabled: boolean;

    Border: object;
    RoundedRectangle: object;
    Content: {
        Label: typeof TranslatableText;
        Icon: typeof Icon;
    };
}

export class Button
    extends Lightning.Component<ButtonTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<ButtonTemplateSpec>
{
    _id: string | undefined;
    _enterAction: any;
    _isSecondary = false;
    _isActive = false;
    _disabled = false;

    readonly Content = this.getByRef("Content")!;
    readonly Label = this.Content.getByRef("Label")!;
    readonly Icon = this.Content.getByRef("Icon")!;

    static override _template(): Lightning.Component.Template<ButtonTemplateSpec> {
        return {
            h: 56,
            collision: true,
            zIndex: 10,
            Border: {},
            RoundedRectangle: {
                rect: true
            },
            Content: {
                h: 56,
                flex: {
                    alignItems: "center",
                    justifyContent: "center"
                },
                Icon: {
                    // w: 24,
                    h: 26,
                    type: Icon
                },
                Label: {
                    // h: 24,
                    type: TranslatableText,
                    text: {
                        textAlign: "center",
                        fontSize: 25,
                        textColor: Colors(theme.button.text.color).get()
                    },
                    alpha: 0
                }
            }
        };
    }

    set enterAction(value: any) {
        this._enterAction = value;
    }

    set actionId(value: string | undefined) {
        this._id = value;
    }

    get actionId(): string | undefined {
        return this._id;
    }

    set hasActive(value: boolean) {
        this.Icon.patch({
            hasActive: value
        });
    }

    set disabled(value: boolean) {
        this._disabled = value;

        this._createBackground();

        this.patch({
            alpha: this._disabled ? 0.5 : 1
        });

        this.Label.patch({
            text: {
                textColor: Colors(theme.button.text.color)
                    .alpha(this._disabled ? 0.3 : 1)
                    .get()
            }
        });
    }

    set label(value: string) {
        this.Label.patch({
            key: value,
            alpha: 1,
            flexItem: { marginLeft: 6, marginRight: 6, marginTop: 2 }
        });
    }

    set icon(value: string) {
        this.Icon.patch({
            icon: value,
            w: 24
        });
    }

    set iconWidth(value: number) {
        this.Icon.patch({
            w: value,
            h: value,
            iconWidth: value
        });
    }

    set useBorder(value: boolean) {
        this._isSecondary = value;

        this._createBackground();
    }

    set isActive(value: boolean) {
        this._isActive = value;

        this._createBackground();
    }

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Icon;
    }

    override _init() {
        this._createBackground();
        this.Content.patch({ w: this.w, h: this.h });
    }

    override _focus() {
        this._createBackground();
    }

    override _unfocus() {
        this._createBackground();
    }

    override _handleEnter() {
        if (this._id) {
            this.fireAncestors("$handleBtnClick", this._id);
        } else {
            return false;
        }
    }

    _getBtnColor() {
        return this._disabled
            ? Colors(theme.button.bg.color).get()
            : this.hasFocus() || this._isActive
              ? Colors(
                    this._isSecondary ? theme.button.bg.colorFocusSecondary || theme.button.bg.colorFocus : theme.button.bg.colorFocus
                ).get()
              : Colors(theme.button.bg.color).get();
    }

    _createBackground() {
        const hasFocus = this.hasFocus();

        if (this._isSecondary) {
            this.patch({
                Border: {
                    x: -6,
                    y: -6,
                    alpha: hasFocus ? 1 : 0,
                    texture: Lightning.Tools.getRoundRect(
                        this.w + 7,
                        63,
                        5,
                        hasFocus ? 3 : 0,
                        this._getBtnColor(),
                        false
                    )
                }
            });
        }

        this.patch({
            RoundedRectangle: {
                x: -1,
                y: -1,
                texture: Lightning.Tools.getRoundRect(this.w, 56, 5, 0, undefined, true, this._getBtnColor())
            }
        });

        this.Label.patch({
            text: {
                textColor:
                    !this._isSecondary && (hasFocus || this._isActive)
                        ? Colors(theme.button.text.colorFocus).get()
                        : Colors(theme.button.text.color).get()
            }
        });
    }

    _handleClick() {
        this.signal("handleClick");
        if (this._id) {
            this.fireAncestors("$handleBtnClick", this._id);
        }
    }
}
