import { Colors, Lightning } from "@lightningjs/sdk";

export class PlayerSubtitle extends Lightning.Component {
    static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpec> {
        return {
            w: 1000,
            // h: 100,
            alpha: 0,
            flex: {
                direction: "row",
                justifyContent: "center"
            },
            // @ts-expect-error types
            Text: {
                flexItem: { grow: 0, shrink: 1 },
                text: {
                    text: "",
                    fontSize: 42,
                    fontFace: "SemiBold",
                    wordWrap: true,
                    maxLines: 3,
                    textAlign: "center",
                    shadow: true,
                    shadowColor: Colors("#000000").alpha(1).get()
                }
            }
        };
    }

    set subtitleText(value: string) {
        if (!value) {
            this.clearSubtitle();
            return;
        }

        this.patch({
            alpha: 1,
            Text: {
                text: {
                    text: value
                }
            }
        });

        // this.stage.forceRenderUpdate();
    }

    clearSubtitle() {
        this.patch({
            Text: {
                text: {
                    text: ""
                }
            },
            alpha: 0
        });
    }
}
