import { Colors, Lightning } from "@lightningjs/sdk";
import { SettingsItemText } from "../layout/SettingsItemText";
import QRCode from "qrcode";
import configs from "../../../../../lib/configs";
import theme from "../../../../../lib/theme";

interface SettingsTermsTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItemText;
}

class SettingsTermsContent extends Lightning.Component<any> implements Lightning.Component.ImplementTemplateSpec<any> {
    readonly QR = this.getByRef("QR")!;

    static override _template(): Lightning.Component.Template<any> {
        return {
            flex: { direction: "column", alignItems: "center", justifyContent: "center" },
            w: 540,
            QR: {
                w: 540,
                h: 540
            },
            Text: {
                y: 24,
                width: 340,
                text: {
                    text: configs.settings.terms,
                    textAlign: "center",
                    textWidth: 340,
                    textColor: Colors(theme.color.text.secondary).get(),
                    fontSize: 28,
                    wrap: true,
                    wrapWidth: 800
                }
            }
        };
    }

    override async _init() {
        this.QR.patch({
            src: await QRCode.toDataURL(configs.settings.terms, {
                errorCorrectionLevel: "H",
                type: "image/jpeg"
            })
        });
    }
}

export class SettingsTerms
    extends Lightning.Component<SettingsTermsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsTermsTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsTermsTemplateSpec> {
        return {
            Layout: {
                type: SettingsItemText,
                title: "about.terms",
                content: SettingsTermsContent
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        // Router.focusWidget("Privacy");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
