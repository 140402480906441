import { Lightning, Router } from "@lightningjs/sdk";
import { Button } from "../Button/Button";
import { MovieModel } from "../../lib/models";
import { getMediaRedirect, isPostTvPlatform, getUserId } from "../../lib/utils";
import { addToWatchList, removeFromWatchList } from "../../api/mutations";
// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import configs from "../../lib/configs";
import theme from "../../lib/theme";

interface MovieActionsTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MovieModel;
    Buttons: typeof List;
    hasPlay: boolean;
}

export class MovieActions
    extends Lightning.Component<MovieActionsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieActionsTemplateSpec>
{
    _boundEventHandler?: any;

    _movieId!: number | string;
    _isFree!: boolean;
    _inWatchlist = false;
    _trailerUrl: string | undefined;
    _hasPlay = false;

    static override _template(): Lightning.Component.Template<MovieActionsTemplateSpec> {
        return {
            collision: true,
            Buttons: {
                type: List,
                collision: true,
                direction: "row",
                spacing: 16,
                w: theme.layout.screenW
            }
        };
    }

    readonly Buttons = this.getByRef("Buttons")!;

    override _getFocused() {
        return this.Buttons;
    }

    override _setup() {
        this._boundEventHandler = this._update.bind(this);
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    override _init() {
        this._setState("WatchState");
    }

    set data(value: MovieModel) {
        this._update(value);
    }

    _update(movie: MovieModel) {
        this._setState("WatchState");

        if (this._movieId !== movie.id) {
            this._trailerUrl = movie.trailer?.url;
            this._movieId = movie.id;
            this._isFree = movie.isFree;
            this._inWatchlist = !!movie.inWatchList;

            this._updateButtons();
        }
    }

    _updateButtons() {
        const items: any[] = [];

        const index = this.Buttons.index;

        if (this._hasPlay) {
            items.push({
                flexItem: { marginRight: 20 },
                w: 240,
                h: 56,
                type: Button,
                actionId: "play",
                label: "actions.watch",
                iconWidth: 20,
                icon: "play",
                signals: {
                    handleClick: "_handleWatchState"
                }
            });
        }

        if (this._trailerUrl) {
            items.push({
                flexItem: { marginRight: 20 },
                w: 240,
                h: 56,
                type: Button,
                actionId: "trailer",
                label: "general.trailer",
                iconWidth: 20,
                icon: "trailer",
                signals: {
                    handleClick: "_handleTrailerState"
                }
            });
        }

        if (configs.modules.watchlist) {
            items.push({
                w: 240,
                h: 56,
                type: Button,
                label: "watchlist.toggle",
                actionId: "watchlist",
                icon: this._inWatchlist ? "remove-watchlist" : "add-watchlist",
                signals: {
                    handleClick: "_handleFavoriteState"
                }
            });
        }

        if (!!items.length) {
            this.Buttons.reload(items);
            this.Buttons.setIndex(index);

            for (const wrapper of this.Buttons.children) {
                wrapper?.patch({
                    collision: true
                });
            }
        } else {
            this.Buttons.clear();
        }
    }

    $handleBtnClick(id: string) {
        if (id === "watchlist") {
            this._handleFavoriteState();
        } else if (id === "play") {
            this._handleWatchState();
        } else {
            this._handleTrailerState();
        }
    }

    async _handleWatchState() {
        getMediaRedirect(this._movieId, {
            isFree: this._isFree
        });
    }

    async _handleTrailerState() {
        Router.navigate(`trailer`);
    }

    async _handleFavoriteState() {
        // this._setState("FavoriteState");

        // this._handleFavoriteState();

        console.log("this._movieId && getUserId()", this._movieId, getUserId());

        if (this._movieId && getUserId()) {
            try {
                if (this._inWatchlist) {
                    await removeFromWatchList(this._movieId!);
                } else {
                    await addToWatchList(this._movieId!);
                }
                this._inWatchlist = !this._inWatchlist;
                // window.selectedMovie!.inWatchList = this._inWatchlist;

                this._updateButtons();
            } catch (e) {
                console.log("e", e);
            }
        } else {
            if (isPostTvPlatform()) {
                Router.focusWidget("PostTvSubscription");
            } else {
                Router.focusWidget("Auth");
            }
        }
    }

    set hasPlay(value: boolean) {
        this._hasPlay = value;
    }
}
