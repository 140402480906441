import { Colors, Lightning } from "@lightningjs/sdk";
import { SettingsItemText } from "../layout/SettingsItemText";
// @ts-expect-error no types available yet
import QRCode from "qrcode";
import theme from "../../../lib/theme";
import configs from "../../../lib/configs";

interface SettingsContactTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsItemText;
}

class SettingsContactContent
    extends Lightning.Component<any>
    implements Lightning.Component.ImplementTemplateSpec<any>
{
    readonly QR = this.getByRef("QR")!;

    static override _template(): Lightning.Component.Template<any> {
        return {
            flex: { direction: "column", alignItems: "center", justifyContent: "center" },
            w: 540,
            QR: {
                w: 540,
                h: 540
            },
            Text: {
                y: 24,
                width: 340,
                text: {
                    text: `${configs.settings.email}`,
                    textAlign: "center",
                    textWidth: 340,
                    textColor: Colors(theme.color.textDark).get(),
                    fontSize: 28,
                    wrap: true,
                    wordWrapWidth: 800
                }
            },
            TextAlteox: {
                y: configs.settings.email ? 42 : 24,
                width: 340,
                text: {
                    text: `hello@alteox.com`,
                    textAlign: "center",
                    textWidth: 340,
                    textColor: Colors(theme.color.textDark).get(),
                    fontSize: 28,
                    wrap: true,
                    wrapWidth: 800
                }
            }
        };
    }

    override async _init() {
        this.QR.patch({
            src: await QRCode.toDataURL(configs.settings.email || "hello@alteox.com", {
                errorCorrectionLevel: "H",
                type: "image/jpeg",
                quality: 1
            })
        });
    }
}

export class SettingsContact
    extends Lightning.Component<SettingsContactTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsContactTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    static override _template(): Lightning.Component.Template<SettingsContactTemplateSpec> {
        return {
            Layout: {
                type: SettingsItemText,
                content: SettingsContactContent,
                title: "about.contact"
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _handleEnter() {
        // Router.focusWidget("Terms");
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }
}
