import { getPayAlteoxSubscriptionLink } from "../../api/mutations";
import widgets from "../../lib/widgets";
import { HotelTVWelcome } from "./HotelTVWelcome";
import { HotelTVLogin } from "./HotelTVLogin";

export default [
    {
        path: "hoteltv-welcome",
        component: HotelTVWelcome,
        options: {
            clearHistory: true
        },
        widgets: widgets
    },
    {
        path: "hoteltv-login",
        component: HotelTVLogin,
        options: {
            clearHistory: true
        },
        widgets: widgets,
        on: (page: any) => {
            return new Promise<void>((resolve, reject) => {
                (async () => {
                    try {
                        page.data = await getPayAlteoxSubscriptionLink();
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                })();
            });
        }
    }
];
