import { Lightning, Router } from "@lightningjs/sdk";
import { CategoryList } from "../components/CategoryList/CategoryList";
import { PAGES } from "../lib/utils";
import theme from "../lib/theme";
import { CategoryModel } from "../lib/models";

interface BrowseTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof CategoryList;
}

interface BrowseTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class BrowseLive
    extends Lightning.Component<BrowseTemplateSpec, BrowseTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<BrowseTemplateSpec>
{
    _wasDetached = true;

    static override _template(): Lightning.Component.Template<BrowseTemplateSpec> {
        return {
            List: {
                type: CategoryList,
                x: theme.menu.w
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    override _firstActive() {
        this.application.emit("setActivePage", PAGES.live.name);
    }

    override _active() {
        window.reloadContinueWatching = !this._wasDetached;
    }

    override _handleBack() {
        if (!Router.getHistory().length) return true;
        Router.go(Router.getHistory().length * -1);
    }

    override _inactive() {
        this._wasDetached = false;

        this.stage.gc();
    }

    set data(value: CategoryModel[]) {
        this.application.emit("clearBackground");

        this.List.addItems(value);
    }
}
