import { Colors, Img, Lightning, Router } from "@lightningjs/sdk";
import { MovieActions } from "../components/MovieActions/MovieActions";
import { MovieDetailsModel } from "../lib/models";
import theme from "../lib/theme";
import { MediaDurationWithProgress } from "../components/MediaDurationWithProgress/MediaDurationWithProgress";
import { SecondaryButton } from "../components/Button/SecondaryButton";

interface MovieTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Backdrop: object;
        Overlay: object;
        Back: typeof SecondaryButton;
        Movie: {
            Top: {
                BaseInfo: {
                    Cover: object;
                    Info: {
                        Name: object;
                        Duration: typeof MediaDurationWithProgress;
                    };
                };
                Description: object;
                Buttons: typeof MovieActions;
            };
        };
    };
}

interface MovieTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Movie
    extends Lightning.Component<MovieTemplateSpec, MovieTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<MovieTemplateSpec>
{
    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MovieTemplateSpec> {
        return {
            collision: true,
            Page: {
                collision: true,
                w: theme.layout.screenW,
                h: theme.layout.screenH,
                Backdrop: {
                    w: theme.layout.screenW,
                    h: theme.layout.screenH,
                    alpha: 0.01
                },
                Overlay: {
                    w: theme.layout.screenW,
                    h: theme.layout.screenH,
                    rect: true,
                    color: Colors(theme.color.overlay).alpha(0.75).get()
                },
                Back: {
                    w: 126,
                    y: 60,
                    x: 50,
                    type: SecondaryButton,
                    label: "general.back",
                    icon: "back",
                    signals: {
                        handleHover: "_handleHover",
                        handleClick: "_handleClick"
                    }
                },
                Movie: {
                    collision: true,
                    w: theme.movie.contentW,
                    h: (h) => h,
                    x: (w) => w / 2,
                    mountX: 0.5,
                    Top: {
                        collision: true,
                        mountY: 0.5,
                        y: (h) => h / 2,
                        w: theme.movie.contentW,
                        // h: (h) => h,
                        flex: { direction: "column", justifyContent: "center" },
                        BaseInfo: {
                            w: (w) => w,
                            flex: { direction: "row", justifyContent: "flex-start" },
                            Cover: {
                                w: theme.mediaItem.posterW,
                                h: theme.mediaItem.posterH,
                                alpha: 0.01,
                                flexItem: { marginRight: 34 }
                            },
                            Info: {
                                flex: { direction: "column", justifyContent: "center" },
                                Name: {
                                    flexItem: { marginBottom: 8 },
                                    w: theme.movie.contentW - theme.mediaItem.posterW - 34,
                                    text: {
                                        text: "",
                                        fontSize: 48,
                                        lineHeight: 50,
                                        textOverflow: "ellipsis",
                                        wordWrap: true,
                                        maxLines: 3
                                    }
                                },
                                Duration: {
                                    type: MediaDurationWithProgress
                                }
                            }
                        },
                        Buttons: {
                            h: 60,
                            flexItem: { marginTop: 24 },
                            type: MovieActions,
                            hasPlay: true
                        },
                        Description: {
                            flexItem: { marginTop: 24 },
                            w: theme.movie.contentW,
                            text: {
                                text: "",
                                fontSize: 30,
                                lineHeight: 32,
                                textOverflow: "ellipsis",
                                wordWrap: true,
                                maxLines: 12,
                                textColor: Colors(theme.color.text).get()
                            }
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Backdrop = this.Page.getByRef("Backdrop")!;
    readonly BackButton = this.Page.getByRef("Back")!;
    readonly Movie = this.Page.getByRef("Movie")!;
    readonly Top = this.Movie.getByRef("Top")!;
    readonly Buttons = this.Top.getByRef("Buttons")!;

    readonly BaseInfo = this.Top.getByRef("BaseInfo")!;
    readonly Cover = this.BaseInfo.getByRef("Cover")!;
    readonly Info = this.BaseInfo.getByRef("Info")!;
    readonly Name = this.Info.getByRef("Name")!;
    readonly Duration = this.Info.getByRef("Duration")!;
    readonly Description = this.Top.getByRef("Description")!;

    override _setup() {
        this._boundEventHandler = this._showPoster.bind(this);
    }

    set data(movie: MovieDetailsModel) {
        window.selectedMovie = movie;

        this.application.emit("clearBackground");
        this.application.emit("updateSelectedMovie", movie);

        this.Backdrop.patch({
            texture: Img(`${movie.backdropUrl}?width=${theme.layout.screenW}`).cover(
                theme.layout.screenW,
                theme.layout.screenH
            )
        });

        this.Cover.patch({
            texture: Img(`${movie.coverUrl}?width=300`).cover(theme.mediaItem.posterW, theme.mediaItem.posterH)
        });

        this.Name.patch({
            text: {
                text: movie.title || ""
            }
        });

        this.Description.patch({
            text: {
                text: movie.description || ""
            }
        });

        this.Duration.patch({
            duration: movie.duration,
            progress: movie.progress?.current
        });

        this.Buttons.patch({
            data: movie
        });
    }

    override _getFocused() {
        return this.Buttons;
    }

    override _active() {
        this.Backdrop.on("txLoaded", this._boundEventHandler);
        this.Cover.on("txLoaded", this._boundEventHandler);
    }

    override _inactive() {
        this.Backdrop.off("txLoaded", this._boundEventHandler);
        this.Cover.off("txLoaded", this._boundEventHandler);
    }

    override _handleLeft() {
        this._setState("BackState");
    }

    override _handleUp() {
        this._setState("BackState");
    }

    // override _handleBack() {
    //     if (!Router.getHistory().length) return true;
    //     Router.go(Router.getHistory().length * -1);
    // }

    _showPoster() {
        this.Backdrop.setSmooth("alpha", 1);
        this.Cover.setSmooth("alpha", 1);
    }

    static override _states() {
        return [
            class BackState extends this {
                override _getFocused(): any {
                    return this.BackButton;
                }

                override _handleLeft() {}

                override _handleRight() {
                    this._setState("");
                }

                override _handleDown() {
                    this._setState("");
                }

                override _handleEnter() {
                    if (!Router.getHistory().length) return true;
                    Router.go(Router.getHistory().length * -1);
                }
            }
        ];
    }
}
