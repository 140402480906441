import { Lightning, Utils } from "@lightningjs/sdk";

interface IconProps {
    icon: string;
}

interface IconTemplateSpec extends Lightning.Component.TemplateSpec {
    hasActive: boolean;
    iconWidth: number;
    iconHeight: number;
    icon: string;
    Icon: IconProps;
    ActiveIcon: IconProps;
}

export class Icon
    extends Lightning.Component<IconTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IconTemplateSpec>
{
    _hasActive = true;
    _iconSize = 24;
    _iconHeight = 24;
    _icon: string | undefined;

    static override _template(): Lightning.Component.Template<IconTemplateSpec> {
        return {
            collision: true,
            zIndex: 20,
            Icon: {},
            ActiveIcon: {
                alpha: 0.01
            }
        };
    }

    set hasActive(value: boolean) {
        this._hasActive = value;
    }

    set icon(value: string) {
        this._icon = value;
        this._updateIcon();
    }

    set iconWidth(value: number) {
        this._iconSize = value;
        this._iconHeight = value;
        this._updateIcon();
    }

    set iconHeight(value: number) {
        this._iconHeight = value;
        this._updateIcon();
    }

    override _focus() {
        if (!this._hasActive) return;

        this.patch({
            Icon: {
                smooth: {
                    alpha: 0.01
                }
            },
            ActiveIcon: {
                smooth: {
                    alpha: 1
                }
            }
        });
    }

    override _unfocus() {
        if (!this._hasActive) return;

        this.patch({
            Icon: {
                smooth: {
                    alpha: 1
                }
            },
            ActiveIcon: {
                smooth: {
                    alpha: 0.01
                }
            }
        });
    }

    _updateIcon() {
        if (!this._icon) return;
        this.patch({
            Icon: {
                texture: Lightning.Tools.getSvgTexture(
                    Utils.asset(`icons/${this._icon}.png`),
                    this._iconSize,
                    this._iconHeight
                )
            }
        });

        if (!this._hasActive) return;

        this.patch({
            ActiveIcon: {
                texture: Lightning.Tools.getSvgTexture(
                    Utils.asset(`icons/${this._icon}-active.png`),
                    this._iconSize,
                    this._iconHeight
                )
            }
        });
    }

    // _handleClick() {
    //     this.signal("handleClick");
    // }

    _handleHover() {
        this.signal("handleHover");
    }
}
