// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { Lightning } from "@lightningjs/sdk";
import { MovieDetails } from "../MovieDetails/MovieDetails";
import { MovieList } from "../MovieList/MovieList";
import { getInfoFromTitle } from "../../lib/utils";
import { MovieCategory } from "../MovieCategory/MovieCategory";
import { CategoryModel, MovieModel } from "../../lib/models";
import theme from "../../lib/theme";

interface CategoryListTemplateSpec extends Lightning.Component.TemplateSpec {
    requestMoreItems: (offset?: number) => Promise<CategoryModel[]>;
    Top: {
        Category: typeof MovieCategory;
        Details: typeof MovieDetails;
    };
    Container: {
        List: {
            type: typeof List;
        };
    };
}

export class CategoryList
    extends Lightning.Component<CategoryListTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<CategoryListTemplateSpec>
{
    _categories: CategoryModel[] = [];
    _boundEventHandler?: any;
    _requestMoreItems: any;

    static override _template(): Lightning.Component.Template<CategoryListTemplateSpec> {
        return {
            x: 0,
            // y: 50,
            y: 0,
            w: theme.layout.screenW - theme.menu.w,
            flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Top: {
                // h: 260,
                // x: DIMENSIONS.pageGap,
                // flex: { direction: "column", justifyContent: "flex-end" },
                w: theme.banner.w - theme.menu.w,
                h: theme.banner.h,
                Category: {
                    flexItem: { marginBottom: 8 },
                    type: MovieCategory
                },
                Details: {
                    // w: 1400,
                    // w: theme.layout.bannerW,
                    type: MovieDetails
                }
            },
            Container: {
                // w: theme.layout.bannerW,
                h: theme.layout.screenH - theme.banner.h,
                w: theme.layout.screenW - theme.menu.w,
                x: 0,
                y: 32,
                clipping: true,
                collision: true,
                List: {
                    // @ts-expect-error no types available yet
                    type: List,
                    collision: true,
                    direction: "column",
                    spacing: 36,
                    h: (h) => h,
                    w: (w) => w,
                    y: 0,
                    x: 0,
                    scroll: {
                        after: 1
                    },
                    gcThreshold: 2,
                    requestThreshold: 4,
                    scrollTransition: {
                        duration: 0.4,
                        timingFunction: "linear"
                    },
                    enableRequests: true,
                    signals: { onIndexChanged: true, onRequestItems: true }
                }
            }
        };
    }
    readonly Container = this.getByRef("Container")!;
    readonly List = this.Container.getByRef("List")!;
    readonly Top = this.getByRef("Top")!;
    readonly Category = this.Top.getByRef("Category")!;
    readonly Details = this.Top.getByRef("Details")!;

    override _setup() {
        this._boundEventHandler = this._updateMovieInfo.bind(this);
    }

    override _getFocused(): Lightning.Component | null | undefined {
        // @ts-expect-error no types available yet
        return this.List;
    }

    override _active() {
        this.application.on("updateSelectedMovie", this._boundEventHandler);

        // @ts-expect-error no types available yet
        if (this.List.items.length === 0) {
            this.onRequestItems();
        }
    }

    override _inactive() {
        this.application.off("updateSelectedMovie", this._boundEventHandler);
    }

    _updateMovieInfo(data: MovieModel) {
        this.Category.patch({
            label: getInfoFromTitle(data?.title).category
        });
    }

    reload(categories: CategoryModel[]) {
        this._categories = categories;

        // @ts-expect-error no types available yet
        this.List.reload(
            this._categories
                .map((category) => ({
                    collision: true,
                    type: MovieList,
                    categoryId: category.id,
                    isContinueWatching: category.isContinueWatching,
                    isTrending: category.isTrending,
                    // categoryTranslations: category.translations,
                    items: category.items || [],
                    lastIndex: 0,
                    categoryKey: category.name
                }))
                .filter((cat: CategoryModel) => !!cat.items.length)
        );

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    addItems(categories: CategoryModel[]) {
        this._categories = categories;

        // @ts-expect-error no types available yet
        if (this.List.items?.length) return;

        this.reload(categories);
    }

    reloadMovies(categories: CategoryModel[]) {
        // @ts-expect-error no types available yet
        this.List.items.map((item: any) => {
            const category = categories.find((cat) => cat.id === item.id)!;
            if (item.items.length !== category.items!.length) {
                item.items = category.items;
            }
        });
    }

    onIndexChanged(data: { previousIndex: number; index: number }) {
        // @ts-expect-error no types available yet
        if (this.List.items) {
            if (data.previousIndex < data.index) {
                // @ts-expect-error no types available yet
                if (typeof this.List?.items[data.previousIndex]?.fadeOut !== "undefined") {
                    // @ts-expect-error no types available yet
                    this.List.items[data.previousIndex].fadeOut();
                }
            } else {
                // @ts-expect-error no types available yet
                if (typeof this.List?.items[data.index - 1]?.fadeOut !== "undefined") {
                    // @ts-expect-error no types available yet
                    this.List.items[data.index - 1].fadeOut();
                }
            }

            // @ts-expect-error no types available yet
            if (this.List.items[data.index] && typeof this.List.items[data.index].fadeIn !== "undefined") {
                // @ts-expect-error no types available yet
                this.List.items[data.index].fadeIn();
            }
        }
    }

    async onRequestItems() {
        if (!this._requestMoreItems) return;

        const data = await this._requestMoreItems(this._categories.length - 1);

        this._categories = this._categories.concat(data);

        // this.List.add(
        return data
            .map((category: CategoryModel) => ({
                collision: true,
                type: MovieList,
                categoryId: category.id,
                isContinueWatching: category.isContinueWatching,
                isTrending: category.isTrending,
                // categoryTranslations: category.translations,
                items: category.items || [],
                lastIndex: 0,
                categoryKey: category.name
            }))
            .filter((cat: CategoryModel) => !!cat.items.length);
        // );

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    $categorySelected(listIndex: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(listIndex);
    }

    $categoryMoviesLoaded({ id, movies }: { id: string; movies: MovieModel[] }) {
        this._categories.map((category) => {
            if (category.id === id) {
                category.items = movies;
            }

            return category;
        });
    }

    set requestMoreItems(value: any) {
        this._requestMoreItems = value;
    }

    get items() {
        // @ts-expect-error no types available yet
        return this.List.items;
    }

    get index() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set index(value: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(value);
    }
}
