import { TranslatableText } from "../TranslatableText/TranslatableText";
import { Colors, Lightning } from "@lightningjs/sdk";
import theme from "../../lib/theme";
import { Button } from "../Button/Button";

interface ErrorPageTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    Text: typeof TranslatableText;
    Button: typeof Button;
}

interface ErrorTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Error
    extends Lightning.Component<ErrorPageTemplateSpec, ErrorTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<ErrorPageTemplateSpec>
{
    readonly Text = this.getByRef("Text")!;

    static override _template(): Lightning.Component.Template<ErrorPageTemplateSpec> {
        return {
            w: 560,
            h: 300,
            mount: 0.5,
            x: (w) => w / 2,
            y: (h) => h / 2,
            flex: { direction: "column", justifyContent: "center", alignItems: "center" },
            texture: Lightning.Tools.getRoundRect(
                560,
                300,
                12,
                0,
                undefined,
                true,
                Colors(theme.color.primary).alpha(1).get()
            ),
            Text: {
                type: TranslatableText,
                key: "error.general",
                text: {
                    textAlign: "center",
                    textWrap: true,
                    wordWrapWidth: 500,
                    fontSize: 32
                }
            },
            Button: {
                flexItem: { marginTop: 24 },
                w: 160,
                type: Button,
                label: "general.back"
            }
        };
    }

    override _getFocused() {
        return this.tag("Button")!;
    }

    set label(value: string) {
        this.Text.key = value;
    }
}
