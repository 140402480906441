import {
    deviceId,
    refreshUserData,
    isSubscriptionAvailable,
    getHomePage,
    isPostTvPlatform,
    PLATFORMS,
    STORAGE_KEYS
} from "../lib/utils";

import configs from "../lib/configs";

// import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";

import { Parameters } from "@firebolt-js/sdk";
import { Profile, Router, Storage } from "@lightningjs/sdk";

import { getSubscriptionData } from "../api/queries";

import { Settings as SettingsPage } from "../pages/settings/Settings";
import { ErrorPage } from "../pages/ErrorPage";
import { Boot } from "../pages/Boot";
import { InfoPage } from "../pages/settings/Info";
import routes from "./routes";

interface NavigateToData {
    entityId: string;
    programType: string;
}

interface DiscoveryParams {
    navigateTo?: {
        data: NavigateToData;
    };
}

interface InitParams {
    discovery?: DiscoveryParams;
}

export default {
    boot: () => {
        return new Promise<void>((resolve) => {
            (async () => {
                const query = Router.getQueryStringParams();
                const queryPlatform = query ? query.platform : undefined;

                const userAgent = navigator.userAgent.toLowerCase();

                const isSamsung = typeof window.tizen !== "undefined";
                const isLG = /(webos|web0s)/i.test(userAgent);

                const tvPlatform = isSamsung
                    ? PLATFORMS.samsung
                    : isLG
                      ? PLATFORMS.lg
                      : userAgent.indexOf("whaletv") > 0
                        ? PLATFORMS.whaletv
                        : userAgent.indexOf("philips") > 0
                          ? PLATFORMS.philips
                          : userAgent.indexOf("nettv") > 0
                            ? PLATFORMS.nettv
                            : typeof window.Hisense_GetDeviceID === "function"
                              ? PLATFORMS.hisense
                              : undefined;

                let platform = queryPlatform || tvPlatform || (await Profile.platform());
                platform = platform.toLowerCase() === "wpe" ? PLATFORMS.comcast : platform;

                Storage.set(STORAGE_KEYS.platform, platform);

                if (isPostTvPlatform()) {
                    Storage.set(STORAGE_KEYS.token, query ? query.token : "");
                }

                // Sentry.init({
                //     dsn: "https://ad27022944804f0b82dafbce3ec38ac5@o599374.ingest.sentry.io/4505169061150720",
                //     integrations: [new Sentry.BrowserTracing()]
                // });

                const generatedDeviceId = await deviceId();

                // metrological is a local build
                if (platform !== "metrological" && configs.posthog) {
                    posthog.init(configs.posthog, {
                        api_host: "https://app.posthog.com",
                        autocapture: false
                    });
                    posthog.identify(generatedDeviceId, { platform: Storage.get(STORAGE_KEYS.platform) });
                    posthog.capture("AppStart");
                }

                await refreshUserData(true);

                if (isSubscriptionAvailable()) {
                    try {
                        const subData = await getSubscriptionData();
                        Storage.set("subscriptionData", subData);
                    } catch (e) {
                        console.log("subscriptionData err", e);
                    }
                }

                resolve();
            })();
        });
    },
    root: () => {
        return new Promise<string>((resolve) => {
            Parameters.initialization()
                .then((init: InitParams) => {
                    // console.log("Parameters", init);

                    if (init.discovery?.navigateTo?.data) {
                        const { entityId, programType } = init.discovery?.navigateTo?.data;

                        if (entityId !== "abc") {
                            resolve(`${programType === "series" ? "series" : "movie"}/${entityId}`);
                            return;
                        }
                    }

                    resolve(getHomePage());
                })
                .catch((error) => {
                    console.log(error);

                    resolve(getHomePage());
                });
        });
    },
    routes: [
        {
            path: "$",
            component: Boot
        },
        ...routes,
        {
            path: "settings",
            component: SettingsPage,
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ],
            options: {
                clearHistory: true
            }
        },
        {
            path: "info",
            component: InfoPage,
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"PostTvSubscription",
                <Lowercase<string>>"Exit"
            ]
        },
        {
            path: "!",
            component: ErrorPage,
            widgets: [<Lowercase<string>>"Menu", <Lowercase<string>>"Exit"],
            options: {}
        }
    ]
};
