export interface VideoConfig {
    startPosition?: number;
    autoplay?: boolean;
    subtitles?: any[];
    onCueEnter?: (str: string) => void;
    preferredSubLang?: string;
}

export interface SourceConfig {
    hls?: string;
    dash?: string;
    progressive?: string;
}

export interface CueEvent {
    type: "cueenter" | "cueexit";
    timestamp: number;
    subtitleId: string;
    start: number;
    end: number;
    text: string;
    html: string;
    vtt: {
        vertical: string;
        align: "start" | "center" | "end";
        size: number;
        line: "auto" | string | number;
        lineAlign: "start" | "center" | "end";
        position: "auto" | string | number;
        positionAlign: "start" | "center" | "end";
        snapToLines: boolean;
    };
}

export const videoPlayerDefaults = {
    debug: false,
    autoplay: true,
    backBufferLength: 10,
    maxBufferLength: 30,
    maxBufferSize: 30
};
