import { Colors, Lightning, Utils, Storage, Img } from "@lightningjs/sdk";
import { TranslatableText } from "../../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../../lib/theme";
import { IAPSubscriptionOptionSingle } from "./IAPSubscriptionOptions/components/IAPSubscriptionOptionSingle";

import { List } from "@lightningjs/ui";
import { STORAGE_KEYS } from "../../../lib/utils";
import configs from "../../../lib/configs";

interface IAPSubscriptionOptionsTemplateSpec extends Lightning.Component.TemplateSpec {
    // url: string;
    LogoContainer: {
        Logo: object;
    };
    Separator: any;
    Text: typeof TranslatableText;
    Options: any;
}

export class IAPSubscriptionOptions
    extends Lightning.Component<IAPSubscriptionOptionsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPSubscriptionOptionsTemplateSpec>
{
    Options = this.getByRef("Options");

    static override _template(): Lightning.Component.Template<IAPSubscriptionOptionsTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.bg.overlay).alpha(0.9).get(),
            LogoContainer: {
                h: 200,
                w: 300,
                mount: 0.5,
                x: (w) => w / 2,
                y: 200,
                Logo: {
                    scale: 1.2,
                    mount: 0.5,
                    x: (w) => w / 2,
                    texture: Img(Utils.asset("images/logo.png")).contain(theme.logo.w, theme.logo.h)
                }
            },
            Separator: {
                mount: 0.5,
                x: (w) => w / 2,
                y: (y) => y / 2 + 75,
                flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                rect: true,
                color: Colors(theme.color.text.tertiary).alpha(0.8).get(),
                w: 2,
                h: 450
            },
            Text: {
                mount: 0.5,
                x: (w) => (w - w / 2) / 2,
                y: (y) => y / 2 + 75,
                type: TranslatableText,
                key: "subscription.info",
                text: {
                    fontSize: 28,
                    wordWrap: true,
                    wordWrapWidth: 840,
                    lineHeight: 34,
                    textColor: Colors(theme.color.text.tertiary).alpha(0.8).get(),
                }
            },
            Options: {
                type: List,
                direction: "column",
                w: 770,
                h: 290,
                mount: 0.5,
                x: (w) => (w + w / 2) / 2,
                y: (y) => y / 2 + 75,
                spacing: 42,
                items: [
                    {
                        type: IAPSubscriptionOptionSingle,
                        data: {
                            key: "subscriptions.monthly",
                            value: ""
                        },
                        h: 122
                    },
                    {
                        type: IAPSubscriptionOptionSingle,
                        data: {
                            key: "subscriptions.yearly",
                            value: ""
                        },
                        h: 122
                    }
                ]
            }
        };
    }

    override _active() {
        const priceKey = Storage.get(STORAGE_KEYS.user)?.isPromoted ? "promoPrices" : "prices";
        // todo types
        // @ts-ignore
        const priceList = configs.iap[Storage.get(STORAGE_KEYS.platform)][priceKey];

        this.Options.items.map((item: any, index: number) => {
            item.data = {
                key: index === 0 ? "subscriptions.monthly" : "subscriptions.yearly",
                value: index === 0 ? priceList.monthly.price : priceList.yearly.price
            };
            return item;
        });
    }

    override _getFocused() {
        return this.Options;
    }

    override _handleEnter() {
        const type = this.Options.index === 0 ? "monthly" : "yearly";
        const priceKey = Storage.get(STORAGE_KEYS.user)?.isPromoted ? "promoPrices" : "prices";
        // todo types
        // @ts-ignore
        const priceList = configs.iap[Storage.get(STORAGE_KEYS.platform)][priceKey];

        this.signal("onConfirmSubscription", {
            price: priceList[type].id
        });
    }
}
