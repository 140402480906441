import configs from "../../lib/configs";
import {
    getBranding,
    getContentByCategory,
    getContentItemById,
    getDashboard,
    getStream,
    getWatchList
} from "../../api/queries";
import posthog from "posthog-js";
import { refreshUserData, STORAGE_KEYS } from "../../lib/utils";
import { Storage } from "@lightningjs/sdk";
import widgets from "../../lib/widgets";
import { Vod } from "./Vod";
import { Movie } from "./Movie";
import { Series } from "./Series";
import { Playback } from "./Playback";
import { Favorite } from "./Favorite";
import { Trailer } from "./Trailer";
import { MediaCategory } from "./MediaCategory";

export default [
    {
        path: "vod",
        component: Vod,
        options: {
            clearHistory: true
        },
        on: (page: any) => {
            return new Promise<void>((resolve, reject) => {
                (async () => {
                    if (configs.modules.branding) {
                        try {
                            page.branding = await getBranding();
                        } catch (e) {}
                    }

                    try {
                        page.data = await getDashboard();
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                })();
            });
        },
        widgets: widgets,
        cache: 60
    },
    {
        path: "category/:categoryId",
        component: MediaCategory,
        on: (page: any, { categoryId }: any) => {
            return new Promise<void>((resolve, reject) => {
                (async () => {
                    try {
                        page.data = await getContentByCategory(categoryId, 0, 72);
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                })();
            });
        },
        widgets: [
            <Lowercase<string>>"Menu",
            <Lowercase<string>>"Auth",
            <Lowercase<string>>"Subscription",
            <Lowercase<string>>"IAPSubscription",
            <Lowercase<string>>"PostTvSubscription",
            <Lowercase<string>>"Exit"
        ],
        cache: 60
    },
    {
        path: "movie/:movieId",
        component: Movie,
        on: (page: any, { movieId }: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    page.data = await getContentItemById(movieId);

                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        },
        widgets: [
            <Lowercase<string>>"Auth",
            <Lowercase<string>>"Subscription",
            <Lowercase<string>>"IAPSubscription",
            <Lowercase<string>>"PostTvSubscription",
            <Lowercase<string>>"Exit"
        ],
    },
    {
        path: "series/:movieId",
        component: Series,
        on: (page: any, { movieId }: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    page.data = await getContentItemById(movieId);

                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        },
        widgets: widgets
    },
    {
        path: "playback/:movieId",
        component: Playback,
        on: (page: any, { movieId }: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    let data;
                    data = await getStream(movieId);
                    posthog.capture("Playback", {
                        id: data.movie?.id || "",
                        title: data.movie?.title || ""
                    });
                    page.data = data;
                    resolve();
                } catch (e) {
                    if (navigator.onLine) {
                        await refreshUserData(true);

                        const user = Storage.get(STORAGE_KEYS.user);
                        if (!user?.subscribed) {
                            page.redirect = true;

                            resolve();
                        } else {
                            reject(e);
                        }
                    } else {
                        reject(e);
                    }
                }
            });
        },
        cache: 60
    },
    {
        path: "favorite",
        component: Favorite,
        on: (page: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    page.data = await getWatchList();

                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        },
        widgets: widgets
    },
    {
        path: "trailer",
        component: Trailer,
        on: (page: any) => {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    page.data = window.selectedMovie?.trailer?.url;

                    resolve();
                } catch (e) {
                    console.log(e);
                    reject(e);
                }
            });
        }
    }
];
