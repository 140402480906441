import { Lightning, Router, Storage } from "@lightningjs/sdk";
import { SettingsCarouselItem } from "../layout/SettingsCarouselItem";
import { isIAPSupported, STORAGE_KEYS, storageSaveUser } from "../../../lib/utils";
import { logout } from "../../../api/mutations";

interface SettingsAuthTemplateSpec extends Lightning.Component.TemplateSpec {
    Layout: typeof SettingsCarouselItem;
}

export class SettingsAuth
    extends Lightning.Component<SettingsAuthTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<SettingsAuthTemplateSpec>
{
    readonly SettingsItem = this.getByRef("Layout")!;

    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<SettingsAuthTemplateSpec> {
        return {
            collision: true,
            zIndex: 10,
            Layout: {
                type: SettingsCarouselItem
            }
        };
    }

    override _getFocused() {
        return this.SettingsItem;
    }

    override _active() {
        this._update();
    }

    override _handleEnter() {
        if (Storage.get(STORAGE_KEYS.user)?.isLoggedIn) {
            (async () => {
                try {
                    const result = await logout();
                    storageSaveUser(result);
                    Storage.set(STORAGE_KEYS.token, result.token);
                } catch (e) {
                    console.log(e);
                }

                this._update();
            })();
        } else {
            if (isIAPSupported()) {
                Router.focusWidget("IAPSubscription");
            } else {
                Router.focusWidget("Auth");
            }
        }
    }

    _handleClick() {
        this._handleEnter();
    }

    _handleHover() {
        this.fireAncestors("$listItemHover", this);
    }

    override _attach() {
        this._boundEventHandler = this._update.bind(this);
        this.application.on("userAuth", this._boundEventHandler);
    }

    override _detach() {
        this.application.off("userAuth", this._boundEventHandler);
    }

    _update() {
        const user = Storage.get(STORAGE_KEYS.user);
        this.SettingsItem.patch({
            title: user?.isLoggedIn
                ? {
                      key: user.username ? "settings.logout.username" : "settings.logout",
                      value: { name: user.username }
                  }
                : "settings.login"
        });
    }
}
