import { Colors, Lightning } from "@lightningjs/sdk";

// @ts-expect-error no types available yet
import { ProgressBar } from "@lightningjs/ui";
import theme from "../../../lib/theme";

interface PlayerProgressTemplateSpec extends Lightning.Component.TemplateSpec {
    progress: number;

    Progress: {
        DurationBar: typeof ProgressBar;
    };
}

export class PlayerProgress
    extends Lightning.Component<PlayerProgressTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<PlayerProgressTemplateSpec>
{
    readonly Progress = this.getByRef("Progress")!;
    readonly DurationBar = this.Progress.getByRef("DurationBar")!;

    static override _template(): Lightning.Component.Template<PlayerProgressTemplateSpec> {
        return {
            Progress: {
                w: (w) => w,
                h: (h) => h,
                DurationBar: {
                    type: ProgressBar,
                    h: (h: number) => h,
                    w: theme.layout.screenW - theme.progress.offset * 2,
                    value: 0,
                    progressColor: Colors(theme.color.contrast).get(),
                    backgroundColor: Colors(theme.color.contrast).alpha(0.4).get()
                }
            }
        };
    }

    // between 0, 1
    set progress(value: number) {
        this.DurationBar.progress(value);
    }
}
