// @ts-expect-error no types available yet
import { InputField } from "@lightningjs/ui";

export default class InputFieldExtended extends InputField {
    handleLeft() {
        // @ts-expect-error types
        this._handleLeft();
    }

    handleRight() {
        // @ts-expect-error types
        this._handleRight();
    }
}
