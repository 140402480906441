import { Colors, Lightning, Registry } from "@lightningjs/sdk";
import { TranslatableText } from "../../../../../../components/TranslatableText/TranslatableText";
import theme from "../../../../../../lib/theme";
import { KeyboardKey } from "../../../../../../components/KeyboardKey/KeyboardKey";
// @ts-expect-error no types available yet
import { Keyboard } from "@lightningjs/ui";
import { Button } from "../../../../../../components/Button/Button";
import InputFieldExtended from "../../../../../../components/InputFieldExtended/InputFieldExtended";

interface IAPEmailKeyboardTemplateSpec extends Lightning.Component.TemplateSpec {
    inputValue: string;
    passwordMode: boolean;

    Background: object;
    Content: {
        Text: typeof TranslatableText;
        InfoText: typeof TranslatableText;
        InputContainer: {
            Wrapper: {
                Input: typeof InputFieldExtended;
            };
        };
        Keyboards: any;
        Button: typeof Button;
    };
}

const keyboardConfig = {
    buttonTypes: {
        default: {
            type: KeyboardKey,
            w: 70,
            h: 70,
            label: {
                text: {
                    fontSize: 32
                }
            }
        },
        Clear: {
            type: KeyboardKey,
            icon: "clear",
            w: 160,
            h: 70
        },
        Space: {
            type: KeyboardKey,
            icon: "space",
            w: 160,
            h: 70
        },
        Backspace: {
            type: KeyboardKey,
            icon: "backspace",
            w: 160,
            h: 70
        },
        Caps: {
            type: KeyboardKey,
            icon: "caps",
            w: 160,
            h: 70
        },
        CursorLeft: {
            type: KeyboardKey,
            icon: "cursor-left",
            w: 78,
            h: 70
        },
        CursorRight: {
            type: KeyboardKey,
            icon: "cursor-right",
            w: 78,
            h: 70
        },
        SwitchLayout: {
            type: KeyboardKey,
            label: {
                text: {
                    fontSize: 21
                }
            },
            w: 160,
            h: 70
        }
    },
    styling: {
        align: "center", //aligns the rows when the width of the Keyboard component is bigger than 0 (zero).
        horizontalSpacing: 4, //spacing between the keys
        verticalSpacing: 4 //spacing between the rows
    }
};

// ¡ ¢ £ ° ¥ ¿
const lettersLayouts = {
    abc: [
        ["a", "b", "c", "d", "e", "f", "g", "h"],
        ["i", "j", "k", "l", "m", "n", "o", "p"],
        ["q", "r", "s", "t", "u", "v", "w", "x"],
        ["y", "z", "-", "_", "¡", "¢", "£", "¿"]
    ],
    ABC: [
        ["A", "B", "C", "D", "E", "F", "G", "H"],
        ["I", "J", "K", "L", "M", "N", "O", "P"],
        ["Q", "R", "S", "T", "U", "V", "W", "X"],
        ["Y", "Z", "-", "_", "¡", "¢", "£", "¿"]
    ],
    123: [
        ["~", "!", "@", "#", "$", "%", "^", "&"],
        ["*", "(", ")", "-", "_", "=", "+", "["],
        ["]", "{", "}", "\\", "|", ";", ":", "'"],
        ['"', ",", ".", "<", ">", "/", "?", "¥"]
    ],
    ÀÇÈÌÒ: [
        ["À", "Á", "Â", "Ã", "Ä", "Å", "Æ", "Ç"],
        ["È", "É", "Ê", "Ë", "Ì", "Í", "Î", "Ï"],
        ["Ð", "Ñ", "Ò", "Ó", "Ô", "Õ", "Ö", "×"],
        ["Ø", "Ù", "Ú", "Û", "Ü", "Ý", "Þ", "ß"]
    ],
    àçèìð: [
        ["à", "á", "â", "ã", "ä", "å", "æ", "ç"],
        ["è", "é", "ê", "ë", "ì", "í", "î", "ï"],
        ["ð", "ñ", "ò", "ó", "ô", "õ", "ö", "÷"],
        ["ø", "ù", "ú", "û", "ü", "ý", "þ", "ÿ"]
    ]
};
const numberLayouts = {
    123: [
        ["1", "2", "3"],
        ["4", "5", "6"],
        ["7", "8", "9"],
        ["@", ".", "0"]
    ]
};

const specialKeysLayout = {
    ABC: [["Caps"], ["Space"], ["Backspace"], ["CursorLeft", "CursorRight"]]
};

const layoutKeysLayout = {
    ABC: [["Clear"], ["SwitchLayout:abc"], ["SwitchLayout:!?&#$%&"], ["SwitchLayout:àçèìð"]]
};

export class IAPEmailKeyboard
    extends Lightning.Component<IAPEmailKeyboardTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPEmailKeyboardTemplateSpec>
{
    readonly Title = this.getByRef("Content")!.getByRef("Text")!;
    readonly Keyboards = this.getByRef("Content")!.getByRef("Keyboards")!;
    readonly Input = this.getByRef("Content")!.getByRef("InputContainer")!.getByRef("Wrapper")!.getByRef("Input")!;
    readonly Button = this.getByRef("Content")!.getByRef("Button")!;

    static override _template(): Lightning.Component.Template<IAPEmailKeyboardTemplateSpec> {
        return {
            w: theme.layout.screenW,
            h: theme.layout.screenH,
            x: 0,
            y: 0,
            alpha: 1,
            rect: true,
            color: Colors(theme.color.container).alpha(0.95).get(),
            Content: {
                w: (w) => w,
                h: (h) => h,
                flex: { direction: "column", alignItems: "center", justifyContent: "center" },
                Text: {
                    flexItem: { marginBottom: 48 },
                    type: TranslatableText,
                    key: "auth.input.email",
                    text: {
                        textAlign: "center",
                        fontFace: "SemiBold",
                        fontSize: 40
                    }
                },
                InputContainer: {
                    w: 1234,
                    h: 66,
                    clipping: true,
                    texture: Lightning.Tools.getRoundRect(
                        1234,
                        66,
                        6,
                        0,
                        undefined,
                        true,
                        Colors(theme.keyboard.inputBg).get()
                    ),
                    Wrapper: {
                        y: 6,
                        x: 32,
                        w: (w) => w - 64,
                        h: (h) => h,
                        clipping: true,
                        Input: {
                            y: 4,
                            w: (w: number) => w,
                            type: InputFieldExtended,
                            inputText: {
                                fontSize: 32,
                                lineHeight: 36,
                                textColor: Colors(theme.color.text).get()
                            },
                            // @ts-expect-error types
                            cursor: {
                                h: 48,
                                w: 2,
                                color: Colors(theme.color.text).get()
                            },
                            maxLabelWidth: (w: number) => w - 37 * 2,
                            labelPositionStatic: false
                        }
                    }
                },
                Keyboards: {
                    flex: { direction: "row", justifyContent: "center", alignItems: "center" },
                    w: 1460,
                    h: 400,
                    children: [
                        {
                            type: Keyboard,
                            w: 160,
                            h: 300,
                            config: { ...keyboardConfig, layouts: specialKeysLayout },
                            currentLayout: "ABC",
                            flexItem: { marginRight: 38 },
                            signals: {
                                onCursorLeft: "_onCursorLeft",
                                onCursorRight: "_onCursorRight",
                                onSpace: "_onSpace",
                                onBackspace: "_onBackspace",
                                onCaps: "_onCaps"
                            }
                        },
                        {
                            type: Keyboard,
                            w: 580,
                            h: 300,
                            flexItem: { marginRight: 38 },
                            config: { ...keyboardConfig, layouts: lettersLayouts },
                            currentLayout: "abc",
                            maxCharacters: 50
                            // signals: { onInputChanged: true, onSpace: true, onLayout: true }
                        },
                        {
                            type: Keyboard,
                            w: 220,
                            h: 300,
                            flexItem: { marginRight: 38 },
                            config: { ...keyboardConfig, layouts: numberLayouts },
                            currentLayout: 123,
                            maxCharacters: 35,
                            signals: { onInputChanged: "_onNumberInput" }
                        },
                        {
                            type: Keyboard,
                            w: 160,
                            h: 300,
                            currentLayout: "ABC",
                            config: { ...keyboardConfig, layouts: layoutKeysLayout },
                            signals: { onClear: "_onClear", onSwitchLayout: "_onLayout" }
                        }
                    ]
                },
                Button: {
                    type: Button,
                    label: "general.next",
                    useBorder: true,
                    w: 380
                }
            }
        };
    }

    override _setup() {
        this.Keyboards.children[1].inputField(this.Input);
    }

    override _active() {}

    override _getFocused(): any {
        return this.Keyboards.children[0];
    }

    override _focus() {
        this._setState("LettersState");

        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    _onLayout(data: any) {
        this.Keyboards.children[1].layout(data.key);
    }

    _onCaps() {
        const current = this.Keyboards.children[1].currentLayout;

        this.Keyboards.children[1].layout(
            current === "àçèìð" ? "ÀÇÈÌÒ" : current === "ÀÇÈÌÒ" ? "àçèìð" : current === "abc" ? "ABC" : "abc"
        );
    }

    _onNumberInput({ input }: { input: string }) {
        this.Keyboards.children[1].add(input);
        this.Keyboards.children[2].clear();
    }

    _onCursorLeft() {
        // @ts-expect-error types
        this.Input.handleLeft();
    }

    _onCursorRight() {
        // @ts-expect-error types
        this.Input.handleRight();
    }

    _onBackspace() {
        // @ts-expect-error types
        this.Keyboards.children[1].onBackspace({ index: this.Input.cursorIndex });
    }

    _onSpace() {
        // @ts-expect-error types
        this.Keyboards.children[1].onSpace({ index: this.Input.cursorIndex });
    }

    _onClear() {
        this.Keyboards.children[1].clear();
    }

    override _handleRight() {
        this._setState("LettersState");

        this._focusKey({
            currentKeyboard: this.Keyboards.children[0],
            nextKeyboard: this.Keyboards.children[1],
            focusFirstKey: true
        });
    }

    override _handleDown() {
        this._setState("ButtonState");
    }

    _focusKey({ currentKeyboard, nextKeyboard, focusFirstKey }: any) {
        const rowIndex = currentKeyboard.rows.findIndex(
            (row: any) => row.ref === currentKeyboard.currentKeyWrapper?.parent?.ref
        );
        if (rowIndex > -1) {
            const layout = nextKeyboard.config.layouts[nextKeyboard.currentLayout];
            const keyToFocus = focusFirstKey ? layout[rowIndex][0] : layout[rowIndex][layout[rowIndex].length - 1];
            nextKeyboard.focus(keyToFocus);
        }
    }

    static override _states() {
        return [
            class ButtonState extends this {
                override _getFocused(): any {
                    return this.Button;
                }

                override _handleUp() {
                    this._setState("LettersState");
                }

                override _handleEnter() {
                    this.signal("onConfirmInput", {
                        // @ts-expect-error types
                        input: this.Input.input
                    });
                }
            },
            class LettersState extends this {
                override _getFocused(): any {
                    return this.Keyboards.children[1];
                }

                override _handleLeft() {
                    this._setState("");

                    this._focusKey({
                        currentKeyboard: this.Keyboards.children[1],
                        nextKeyboard: this.Keyboards.children[0],
                        focusFirstKey: false
                    });
                }

                override _handleRight() {
                    this._setState("NumbersState");

                    this._focusKey({
                        currentKeyboard: this.Keyboards.children[1],
                        nextKeyboard: this.Keyboards.children[2],
                        focusFirstKey: true
                    });
                }
            },

            class NumbersState extends this {
                override _getFocused(): any {
                    return this.Keyboards.children[2];
                }

                override _handleLeft() {
                    this._setState("LettersState");

                    this._focusKey({
                        currentKeyboard: this.Keyboards.children[2],
                        nextKeyboard: this.Keyboards.children[1],
                        focusFirstKey: false
                    });
                }

                override _handleRight() {
                    this._setState("LayoutState");

                    this._focusKey({
                        currentKeyboard: this.Keyboards.children[2],
                        nextKeyboard: this.Keyboards.children[3],
                        focusFirstKey: true
                    });
                }
            },
            class LayoutState extends this {
                override _getFocused(): any {
                    return this.Keyboards.children[3];
                }

                override _handleLeft() {
                    this._setState("NumbersState");

                    this._focusKey({
                        currentKeyboard: this.Keyboards.children[3],
                        nextKeyboard: this.Keyboards.children[2],
                        focusFirstKey: false
                    });
                }

                override _handleRight() {}
            }
        ];
    }

    set inputValue(value: string) {
        // Registry.setTimeout(() => {
        this._onClear();
        this.Keyboards.children[1].add(value || "");
        // }, 2);
    }

    set passwordMode(value: boolean) {
        this.Input.patch({
            // @ts-expect-error types
            passwordMode: value
        });

        this.Title.patch({
            key: value ? "auth.input.password" : "auth.input.email"
        });
    }
}
