import { Colors, Lightning } from "@lightningjs/sdk";
import { Button } from "./Button";
import theme from "../../../lib/theme";

export class LargeButton extends Button {
    override _getBtnColor() {
        return this._disabled
            ? Colors(theme.button.disabledColor).get()
            : this.hasFocus() || this._isActive
              ? Colors(theme.livetvButton.bg.colorFocus).get()
              : Colors(theme.livetvButton.bg).get();
    }

    override _createBackground() {
        this.patch({
            RoundedRectangle: {
                texture: Lightning.Tools.getRoundRect(
                    this.w,
                    this.h,
                    theme.livetvButton.border.radius,
                    0,
                    undefined,
                    true,
                    this._getBtnColor()
                )
            }
        });

        this.Label.patch({
            text: {
                fontSize: 32,
                fontFamily: 'Regular',
                lineHeight:38,
                textColor: Colors(theme.button.text.color).get()
            }
        });
    }
}
