import Hls from "hls.js";
import { Router, VideoPlayer } from "@lightningjs/sdk";
import { Metrics } from "@firebolt-js/sdk";
import theme from "../theme";
import { VideoConfig, videoPlayerDefaults } from "./interface";

let hls: Hls | null = null;

const handleUnrecoverableError = (player: Hls, errorEvent: string) => {
    player?.destroy();
};

const unload = (videoEl: HTMLVideoElement) => {
    if (hls?.destroy) {
        hls.destroy();
        hls = null;
    }

    console.log("videoEl", videoEl);
    if (videoEl) {
        videoEl.removeAttribute("src");
        Array.from(videoEl.querySelectorAll("track")).forEach((track) => track.remove());
    }
};

export const loader = (src: string, videoEl: HTMLVideoElement, config: VideoConfig = {}): Promise<void> => {
    return new Promise(async (resolve) => {
        unload(videoEl);

        videoEl.setAttribute("poster", "/static/images/poster.png");
        videoEl.setAttribute("crossorigin", "crossorigin");
        videoEl.style.setProperty("background", theme.color.bg.primary);

        if (src.indexOf(".m3u") > -1 && Hls.isSupported()) {
            console.log("Loading hls source:", src);
            hls = new Hls({ ...videoPlayerDefaults, ...config });

            if (config.subtitles?.length) {
                config.subtitles.forEach((subtitle) => {
                    if (subtitle.url) {
                        const track = document.createElement("track");
                        track.kind = "subtitles";
                        track.label = subtitle.label;
                        track.srclang = subtitle.lang;
                        track.src = subtitle.url;
                        track.default = false;
                        videoEl.appendChild(track);
                    }
                });
            }

            hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                console.log("video and hls.js are now bound together !");
            });
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                console.log("manifest loaded, found " + data.levels.length + " quality level");
                resolve();
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                console.log("ERROR", data);
                if (hls) {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.log("fatal network error encountered, try to recover");
                                Metrics.error(Metrics.ErrorType.MEDIA, "NETWORK_ERROR", "playback network error", true);

                                if (navigator.onLine) {
                                    hls.startLoad();
                                    switch (data.details) {
                                        case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                                            if (data.frag) {
                                                hls.currentLevel = data.frag.start + data.frag.duration + 0.1;
                                            } else {
                                                hls.startLoad();
                                            }
                                            break;

                                        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                                            handleUnrecoverableError(hls, event);
                                            break;

                                        default:
                                            hls.startLoad();
                                            break;
                                    }
                                } else {
                                    Router.navigate("!");
                                }

                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.log("fatal media error encountered, try to recover");
                                Metrics.error(Metrics.ErrorType.MEDIA, "MEDIA_ERROR", "playback media error", true);

                                switch (data.details) {
                                    case Hls.ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                                        handleUnrecoverableError(hls, event);
                                        break;
                                    default:
                                        hls.recoverMediaError();
                                        break;
                                }

                                break;
                            default:
                                Metrics.error(
                                    Metrics.ErrorType.MEDIA,
                                    "MEDIA_FATAL_ERROR",
                                    "playback fatal error",
                                    true
                                );

                                // cannot recover
                                handleUnrecoverableError(hls, event);
                                break;
                        }
                    }
                }
            });

            hls.loadSource(src);
            hls.attachMedia(videoEl);

            videoEl.textTracks.onaddtrack = () => {
                toggleSubtitle(config.preferredSubLang || "off");
            };
        } else {
            videoEl.setAttribute("src", src);
            videoEl.load();

            videoEl.currentTime = config.startPosition || 0;

            videoEl
                .play()
                .then((r) => console.log("play"))
                .catch((e) => console.log(e));

            resolve();
        }
    });
};

export const unloader = (videoEl: HTMLVideoElement): Promise<void> => {
    return new Promise((resolve) => {
        unload(videoEl);
        resolve();
    });
};

export const setPlaybackSpeed = (speed: number) => {
    if (hls) {
        const videoEl = hls.media;
        if (videoEl) {
            videoEl.playbackRate = speed;
            console.log(`HLS playback speed set to ${speed}`);
        } else {
            console.warn("HLS media element not found for playback speed adjustment.");
        }
    } else {
        const videoEl = VideoPlayer._videoEl;
        if (videoEl) {
            videoEl.playbackRate = speed;
            console.log(`Default video playback speed set to ${speed}`);
        } else {
            console.warn("Default video element not found for playback speed adjustment.");
        }
    }
};

export const toggleSubtitle = (lang: string) => {
    if (hls) {
        const videoEl = hls.media;
        if (videoEl) {
            const tracks = Array.from(videoEl.textTracks);
            if (lang === "off") {
                tracks.forEach((track) => (track.mode = "disabled"));
            } else {
                tracks.forEach((track) => {
                    track.mode = track.language === lang ? "showing" : "disabled";
                });
            }
        }
    } else {
        const videoEl = VideoPlayer._videoEl;
        if (videoEl) {
            // videoEl.playbackRate = speed;
            // console.log(`Default video playback speed set to ${speed}`);
        } else {
            console.warn("Default video element not found for playback speed adjustment.");
        }
    }
};

export const getPlaybackSpeed = () => {
    if (hls) {
        return hls.media?.playbackRate || 1;
    }
    return 1;
};

export const destroyPlayer = () => {};
