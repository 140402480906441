import { Colors, Img, Lightning, Router, Utils } from "@lightningjs/sdk";
import { SecondaryButton } from "../../components/input/Button/SecondaryButton";
import theme from "../../lib/theme";
import { MediaActions } from "./components/MediaActions/MediaActions";
import { MediaDurationWithProgress } from "./components/MediaDurationWithProgress/MediaDurationWithProgress";
import { MediaModel } from "../../lib/models";

interface MovieTemplateSpec extends Lightning.Component.TemplateSpec {
    Page: {
        Backdrop: object;
        Overlay: object;
        Back: typeof SecondaryButton;
        Movie: {
            Top: {
                BaseInfo: {
                    Cover: {
                        Placeholder: object;
                        Image: object;
                    };
                    Info: {
                        Name: object;
                        Labels: {
                            Year: typeof Object;
                            Duration: typeof MediaDurationWithProgress;
                        };
                    };
                };
                Description: object;
                Buttons: typeof MediaActions;
            };
        };
    };
}

interface MovieTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Movie
    extends Lightning.Component<MovieTemplateSpec, MovieTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<MovieTemplateSpec>
{
    _boundEventHandler?: any;

    static override _template(): Lightning.Component.Template<MovieTemplateSpec> {
        return {
            collision: true,
            Page: {
                collision: true,
                w: theme.layout.w,
                h: theme.layout.h,
                Backdrop: {
                    w: theme.layout.w,
                    h: theme.layout.h,
                    alpha: 0.01
                },
                Overlay: {
                    w: theme.layout.w,
                    h: theme.layout.h,
                    rect: true,
                    color: Colors(theme.color.bg.overlay).alpha(0.75).get()
                },
                Back: {
                    w: 126,
                    y: 60,
                    x: 50,
                    type: SecondaryButton,
                    label: "general.back",
                    icon: "back",
                    signals: {
                        handleHover: "_handleHover",
                        handleClick: "_handleClick"
                    }
                },
                Movie: {
                    collision: true,
                    w: theme.movie.content.w,
                    h: (h) => h,
                    x: (w) => w / 2,
                    mountX: 0.5,
                    Top: {
                        collision: true,
                        mountY: 0.5,
                        y: (h) => h / 2,
                        w: theme.movie.content.w,
                        // h: (h) => h,
                        flex: { direction: "column", justifyContent: "center" },
                        BaseInfo: {
                            w: (w) => w,
                            flex: { direction: "row", justifyContent: "flex-start" },

                            Cover: {
                                w: theme.mediaItem.w,
                                h: theme.mediaItem.h,
                                flexItem: { marginRight: 34 },
                                Placeholder: {
                                    mount: 0.5,
                                    w: theme.mediaPlaceholder.w,
                                    h: theme.mediaPlaceholder.h,
                                    x: (w) => w / 2,
                                    y: (y) => y / 2,
                                    texture: Img(Utils.asset("images/placeholder.png")).contain(
                                        theme.mediaPlaceholder.w,
                                        theme.mediaPlaceholder.h
                                    )
                                },
                                Image: {
                                    w: theme.mediaItem.w,
                                    h: theme.mediaItem.h,
                                    alpha: 0.01,
                                    flexItem: { marginRight: 34 },
                                    shader: {
                                        type: Lightning.shaders.RoundedRectangle,
                                        radius: theme.mediaItem.border.radius,
                                        stroke: 1,
                                        strokeColor: Colors(theme.color.primary).alpha(0).get()
                                    }
                                }
                            },
                            Info: {
                                flex: { direction: "column", justifyContent: "center" },
                                Name: {
                                    flexItem: { marginBottom: 8 },
                                    w: theme.movie.content.w - theme.mediaItem.w - 34,
                                    text: {
                                        text: "",
                                        fontSize: 48,
                                        lineHeight: 50,
                                        textOverflow: "ellipsis",
                                        fontFace: "Heavy",
                                        wordWrap: true,
                                        maxLines: 3
                                    }
                                },
                                Labels: {
                                    flex: { direction: "row" },
                                    flexItem: { marginBottom: 10 },
                                    Year: {
                                        text: {
                                            text: "",
                                            fontSize: 30,
                                            wordWrap: true,
                                            lineHeight: 30,
                                            fontFace: "Secondary-Regular"
                                        }
                                    },
                                    Duration: {
                                        type: MediaDurationWithProgress
                                    }
                                }
                            }
                        },
                        Buttons: {
                            h: 60,
                            flexItem: { marginTop: 24 },
                            type: MediaActions,
                            hasPlay: true
                        },
                        Description: {
                            flexItem: { marginTop: 24 },
                            w: theme.movie.content.w,
                            text: {
                                text: "",
                                fontSize: 30,
                                lineHeight: 36,
                                textOverflow: "ellipsis",
                                wordWrap: true,
                                maxLines: 12,
                                textColor: Colors(theme.color.text.primary).get()
                            }
                        }
                    }
                }
            }
        };
    }

    readonly Page = this.getByRef("Page")!;
    readonly Backdrop = this.Page.getByRef("Backdrop")!;
    readonly BackButton = this.Page.getByRef("Back")!;
    readonly Movie = this.Page.getByRef("Movie")!;
    readonly Top = this.Movie.getByRef("Top")!;
    readonly Buttons = this.Top.getByRef("Buttons")!;

    readonly BaseInfo = this.Top.getByRef("BaseInfo")!;
    readonly Cover = this.BaseInfo.getByRef("Cover")!;
    readonly CoverImage = this.Cover.getByRef("Image")!;
    readonly Info = this.BaseInfo.getByRef("Info")!;
    readonly Name = this.Info.getByRef("Name")!;
    readonly Labels = this.Info.getByRef("Labels")!;
    readonly Duration = this.Labels.getByRef("Duration")!;
    readonly Year = this.Labels.getByRef("Year")!;
    readonly Description = this.Top.getByRef("Description")!;

    override _setup() {
        this._boundEventHandler = this._showPoster.bind(this);
    }

    set data(movie: MediaModel) {
        window.selectedMovie = movie;

        this.application.emit("clearBackground");
        this.application.emit("updateSelectedMovie", movie);

        this.Backdrop.patch({
            texture: Img(`${movie.backdropUrl}?width=${theme.layout.w}`).cover(
                theme.layout.w,
                theme.layout.h
            )
        });

        this.CoverImage.patch({
            texture: Img(`${movie.coverUrl}?width=300`).cover(theme.mediaItem.w, theme.mediaItem.h)
        });

        this.Name.patch({
            text: {
                text: movie.title || ""
            }
        });

        this.Description.patch({
            text: {
                text: movie.description || ""
            }
        });

        this.Duration.patch({
            duration: movie.duration,
            progress: movie.progress?.current
        });

        const hasDuration = movie.duration;
        const year = movie.releaseYear?.toString() || "";
        this.Year.patch({
            text: {
                text: year + (year && hasDuration ? "  •" : "")
            },
            flexItem: { marginRight: !!year ? 10 : 0 }
        });

        this.Buttons.patch({
            data: movie
        });
    }

    override _getFocused() {
        return this.Buttons;
    }

    override _active() {
        this.Backdrop.on("txLoaded", this._boundEventHandler);
        this.CoverImage.on("txLoaded", this._boundEventHandler);
    }

    override _inactive() {
        this.Backdrop.off("txLoaded", this._boundEventHandler);
        this.CoverImage.off("txLoaded", this._boundEventHandler);
    }

    override _handleLeft() {
        this._setState("BackState");
    }

    override _handleUp() {
        this._setState("BackState");
    }

    // override _handleBack() {
    //     if (!Router.getHistory().length) return true;
    //     Router.go(Router.getHistory().length * -1);
    // }

    _showPoster() {
        this.Backdrop.setSmooth("alpha", 1);
        this.CoverImage.setSmooth("alpha", 1);
    }

    static override _states() {
        return [
            class BackState extends this {
                override _getFocused(): any {
                    return this.BackButton;
                }

                override _handleLeft() {}

                override _handleRight() {
                    this._setState("");
                }

                override _handleDown() {
                    this._setState("");
                }

                override _handleEnter() {
                    if (!Router.getHistory().length) return true;
                    Router.back();
                }
            }
        ];
    }
}
