import { IAPData } from "./models";
import { syncExternalSubscription } from "../api/mutations";
import { storageSaveUser } from "./utils";

if (window.Android) {
    window.setSubscriptionData = async (purchaseInfo: IAPData, ignoreResponse?: boolean) => {
        console.log(
            `Set sub data :${JSON.stringify({
                purchaseInfo,
                ignoreResponse
            })}}`
        );
        window.dispatchEvent(
            new CustomEvent("debug", {
                detail: {
                    name: "setSubData",
                    data: purchaseInfo || {}
                }
            })
        );

        if (!ignoreResponse) {
            // show loading if user initiated sub
            window.dispatchEvent(
                new CustomEvent("externalSubscriptionSync", {
                    detail: { syncing: true }
                })
            );
        }

        // console.log(`purchaseInfo ${purchaseInfo}`);
        // console.log(`typeof purchaseInfo ${typeof purchaseInfo}`);

        try {
            // TODO: types
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: for later
            purchaseInfo = JSON.parse(purchaseInfo);
        } catch (e) {
            console.log(e);
        }

        const { purchaseToken, userId } = purchaseInfo;

        let hasError = false;
        try {
            const res = await syncExternalSubscription(purchaseInfo);

            storageSaveUser({ ...res, userId: purchaseToken || userId });

            console.log(`SAVE RES ${JSON.stringify(res)}`);
        } catch (e) {
            console.log(`sync err ${JSON.stringify({ err: e })}`);
            hasError = !ignoreResponse;
        }

        if (!ignoreResponse) {
            // let data sync on all sides, otherwise subscription popup will be shown again
            setTimeout(
                () => {
                    window.dispatchEvent(
                        new CustomEvent("externalSubscriptionSync", {
                            detail: { syncing: false, error: hasError }
                        })
                    );
                },
                hasError ? 0 : 3000
            );
        }
    };

    window.handleAppBackPressed = () => {
        window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 8 }));
    };
}
