import { Colors, Img, Lightning, Registry, Router, Storage, Utils } from "@lightningjs/sdk";
import { storageSaveUser, deviceId, STORAGE_KEYS } from "../../lib/utils";
import QRCode from "qrcode";
import { TranslatableText } from "../../components/data-display/TranslatableText/TranslatableText";
import posthog from "posthog-js";
import { Loading } from "../../components/feedback/Loading/Loading";
import theme from "../../lib/theme";
import { getPayAlteoxSubscriptionLink } from "../../api/mutations";
import configs from "../../lib/configs";
import { getUserStatus } from "../../api/queries";

interface AuthTemplateSpec extends Lightning.Component.TemplateSpec {
    Loading: typeof Loading;
    Box: {
        Background: object;
        Content: {
            LogoContainer: {
                Logo: object;
            };
            Label: typeof TranslatableText;
            FreeTrial: typeof TranslatableText;
            Price: typeof TranslatableText;
            QrCode: object;
            Link: object;
            Notice: typeof TranslatableText;
        };
    };
}

export class Auth
    extends Lightning.Component<AuthTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<AuthTemplateSpec>
{
    _activePage: undefined | string = "home";
    _items: any[] = [];

    _loginTimeout: undefined | number;

    readonly Loading = this.getByRef("Loading")!;
    readonly Box = this.getByRef("Box")!;
    readonly Content = this.Box.getByRef("Content")!;
    readonly QrCode = this.Content.getByRef("QrCode")!;
    readonly Link = this.Content.getByRef("Link")!;
    readonly Price = this.Content.getByRef("Price")!;
    // readonly FreeTrial = this.Content.getByRef("FreeTrial")!;

    static override _template(): Lightning.Component.Template<AuthTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            x: 0,
            y: 0,
            rect: true,
            color: Colors(theme.color.bg.overlay).alpha(0.6).get(),
            alpha: 0,
            zIndex: 40,
            collision: true,
            Loading: {
                type: Loading,
                alpha: 1
            },
            Box: {
                w: 720,
                h: 780,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                alpha: 0,
                Background: {
                    x: -1,
                    y: -1,
                    texture: Lightning.Tools.getRoundRect(
                        720,
                        780,
                        12,
                        0,
                        undefined,
                        true,
                        Colors(theme.color.bg.primary).get()
                    )
                },
                Content: {
                    w: (w) => w,
                    h: (h) => h,
                    flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                    LogoContainer: {
                        h: 200,
                        w: 300,
                        Logo: {
                            mount: 0.5,
                            x: (w) => w / 2,
                            y: (h) => h / 2 - 40,
                            scale: 1.2,
                            texture: Img(Utils.asset("images/logo.png")).contain(theme.logo.w, theme.logo.h)
                        }
                    },
                    Price: {
                        flexItem: { marginBottom: 24 },
                        type: TranslatableText,
                        translate: {
                            key: "subscription.price",
                            value: ""
                        },
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 28,
                            lineHeight: 36,
                            wordWrap: true,
                            wordWrapWidth: 680,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    },
                    QrCode: { w: 260, h: 260 },
                    Link: {
                        flexItem: { marginTop: 16 },
                        h: 40,
                        text: {
                            text: "",
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 28,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    },
                    Notice: {
                        type: TranslatableText,
                        skipEmpty: true,
                        key: "subscription.notice",
                        flexItem: { marginTop: 16 },
                        h: 40,
                        text: {
                            textAlign: "center",
                            fontFace: "SemiBold",
                            fontSize: 28,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    }
                }
            }
        };
    }

    override _getFocused() {
        return this;
    }

    override _focus() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    override _unfocus() {
        this.patch({
            smooth: {
                alpha: 0
            }
        });
    }

    override _handleUp() {
        // needed to prevent focus on page
    }

    override _handleLeft() {
        // needed to prevent focus on page
    }

    override _handleRight() {
        // needed to prevent focus on page
    }

    override _handleBack() {
        Router.focusPage();
    }

    override _handleDown() {
        //
    }

    override async _active() {
        this.Loading.patch({
            alpha: 1
        });

        this.Box.patch({
            alpha: 0
        });

        const data = await getPayAlteoxSubscriptionLink();

        // this.FreeTrial.patch({
        //     alpha: data.freeTrialAvailable ? 1 : 0
        // });
        //
        this.Price.patch({
            translate: {
                key: "subscription.price",
                value: {
                    appName: configs.appName,
                    value: Storage.get("subscriptionData")?.price || ""
                }
            }
        });

        const qrcode = await QRCode.toDataURL(data.url, {
            errorCorrectionLevel: "H",
            type: "image/jpeg"
        });
        this.QrCode.patch({
            src: qrcode
        });
        this.Link.patch({
            text: {
                text: data?.url
            }
        });

        this.Loading.patch({
            alpha: 0
        });
        this.Box.patch({
            alpha: 1
        });

        await this._checkLogin();
    }

    override async _inactive() {
        this.QrCode.patch({
            src: undefined
        });
        this.Link.patch({
            text: {
                text: ""
            }
        });
    }

    async _checkLogin() {
        try {
            const res = await getUserStatus();

            if (res && res.isLoggedIn && res.subscribed) {
                posthog.capture("SuccessfulAuth", {
                    token: await deviceId()
                });

                storageSaveUser(res);
                Storage.set(STORAGE_KEYS.token, res.token);

                this.application.emit("userAuth", true);

                Router.focusPage();

                return true;
            } else {
                if (Router.getActiveWidget()?.ref === "Auth") {
                    this._setLoginTimeout();
                }
            }

            // storageSaveUser(res);

            // if (res.canWatch) {
            // this.application.emit("userAuth", true);
            // await this._showLoggedInPopup();

            //
            // Router.focusPage();
            // } else {
            //     this._setLoginTimeout();
            // }
        } catch (e) {
            this._setLoginTimeout();
        }
    }

    _setLoginTimeout() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        this._loginTimeout = Registry.setTimeout(async () => {
            await _this._checkLogin();
        }, 5000);
    }

    _handleClick() {
        this._handleBack();
    }
}
