import { Lightning, Colors, Storage, Router, Registry } from "@lightningjs/sdk";
import QRCode from "qrcode";
import posthog from "posthog-js";
import { TranslatableText } from "../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../lib/theme";
import { getUserStatus } from "../../api/queries";
import { deviceId, getHomePage, isSubscriptionActive, STORAGE_KEYS, storageSaveUser } from "../../lib/utils";

interface HotelTVLoginTemplateSpec extends Lightning.Component.TemplateSpec {
    Background: object;
    // Back: typeof SecondaryButton;
    // Loading: typeof Loading;
    Container: {
        Content: {
            Title: typeof TranslatableText;
            Subtitle: typeof TranslatableText;
            QrCode: object;
            Link: object;
        };
    };
}

export class HotelTVLogin
    extends Lightning.Component<HotelTVLoginTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<HotelTVLoginTemplateSpec>
{
    // readonly Back = this.getByRef("Back")!;
    // readonly Loading = this.getByRef("Loading")!;
    readonly QrCode = this.tag("Container.Content.QrCode")!;
    readonly Link = this.tag("Container.Content.Link")!;

    _loginTimeout = null;
    _userType: string = "hotel";

    override set params(args: { userType?: string }) {
        if (args.userType) {
            this._userType = args.userType;

            // this.patch({
            //     Back: {
            //         label: this._userType === "hotel" ? "loginQR.back.hotel" : "loginQR.back.guesthouse"
            //     }
            // });
        }
    }

    static override _template(): Lightning.Component.Template<HotelTVLoginTemplateSpec> {
        return {
            w: theme.layout.w - theme.menu.w,
            h: theme.layout.h,
            x: theme.menu.w,
            Background: {
                w: (w) => w,
                h: (h) => h,
                rect: true,
                color: Colors(theme.loginQR.bg.color).get()
            },
            // Loading: {
            //     type: Loading,
            //     alpha: 1
            // },
            // Back: {
            //     type: SecondaryButton,
            //     x: theme.loginQR.back.x,
            //     y: theme.loginQR.back.y,
            //     icon: "back-arrow",
            //     isEnlarged: true,
            //     signals: {
            //         handleClick: "_handleBackClick"
            //     }
            // },
            Container: {
                w: theme.loginQR.container.w,
                h: theme.loginQR.container.h,
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                rect: true,
                color: Colors(theme.loginQR.container.bg.color).get(),
                shader: { type: Lightning.shaders.RoundedRectangle, radius: theme.loginQR.container.border.radius },
                Content: {
                    // w: (w) => w ,
                    // w: (h) => h,
                    mount: 0.5,
                    x: (w) => w / 2,
                    y: (h) => h / 2,
                    flex: {
                        direction: "column",
                        alignItems: "center"
                    },
                    Title: {
                        type: TranslatableText,
                        key: "loginQR.title",
                        text: {
                            fontSize: theme.loginQR.content.title.fontSize,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    },
                    Subtitle: {
                        type: TranslatableText,
                        key: "loginQR.subtitle",
                        text: {
                            fontSize: theme.loginQR.content.subtitle.fontSize,
                            textColor: Colors(theme.color.text.primary).get()
                        }
                    },
                    QrCode: {
                        flexItem: { marginTop: 50 },
                        w: 260,
                        h: 260
                    },
                    Link: {
                        flexItem: { marginTop: 40 },
                        text: {
                            fontSize: 26
                        }
                    }
                }
            }
        };
    }

    set data(data: any) {
        QRCode.toDataURL(data.url, {
            errorCorrectionLevel: "H",
            type: "image/jpeg"
        }).then((qrcode) => {
            this.QrCode.patch({
                src: qrcode
            });
        });

        this.Link.patch({
            text: {
                text: data?.url
            }
        });
    }

    override async _active() {
        await this._checkLogin();
    }

    async _checkLogin() {
        // todo sync with Auth widget
        try {
            const res = await getUserStatus();

            storageSaveUser(res);
            Storage.set(STORAGE_KEYS.token, res.token);

            if (isSubscriptionActive()) {
                posthog.capture("SuccessfulAuth", {
                    token: await deviceId()
                });

                Router.navigate(getHomePage());

                return true;
            } else {
                this._setLoginTimeout();
            }
        } catch (e) {
            this._setLoginTimeout();
        }
    }

    _setLoginTimeout() {
        const _this = this;
        this._loginTimeout = Registry.setTimeout(async () => {
            await _this._checkLogin();
        }, 5000);
    }
}
