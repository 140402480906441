import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../../../../../../components/TranslatableText/TranslatableText";
import theme from "../../../../../../lib/theme";

interface IAPEmailKeyboardInputsTemplateSpec extends Lightning.Component.TemplateSpec {
    label: string;
    value: string;

    Background: object;
    Text: typeof TranslatableText;
}

export class IAPEmailKeyboardInputs
    extends Lightning.Component<IAPEmailKeyboardInputsTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPEmailKeyboardInputsTemplateSpec>
{
    _label = "";

    readonly Text = this.getByRef("Text")!;

    static override _template(): Lightning.Component.Template<IAPEmailKeyboardInputsTemplateSpec> {
        return {
            w: 650,
            h: 91,
            texture: Lightning.Tools.getRoundRect(
                650,
                91,
                6,
                0,
                undefined,
                true,
                Colors(theme.color.primary).alpha(0.8).get()
            ),
            Text: {
                mountY: 0.5,
                x: 25,
                y: (h) => h / 2 + 2,
                type: TranslatableText,
                key: "",
                text: {
                    textAlign: "center",
                    fontFace: "SemiBold",
                    fontSize: 26
                }
            }
        };
    }

    override _getFocused(): any {
        return this;
    }

    override _focus() {
        this.patch({
            texture: Lightning.Tools.getRoundRect(
                650,
                91,
                6,
                1,
                Colors(theme.color.focus).get(),
                true,
                Colors(theme.color.container).get()
            )
        });
    }

    override _unfocus() {
        this.patch({
            texture: Lightning.Tools.getRoundRect(
                650,
                91,
                6,
                0,
                undefined,
                true,
                Colors(theme.color.primary).alpha(0.8).get()
            )
        });
    }

    set label(value: string) {
        this._label = value;
        this.Text.patch({
            key: value
        });
    }

    set value(value: string) {
        if (value === "") {
            this.Text.patch({
                key: value || "email"
            });
        } else {
            this.Text.patch({
                key: value
            });
        }
    }
}
