import { Language, Lightning } from "@lightningjs/sdk";
import { CategoryList } from "../../components/data-display/CategoryList/CategoryList";
import { PAGES } from "../../lib/utils";
import { getDashboard } from "../../api/queries";
import theme from "../../lib/theme";
import { BrandingModel, CategoryModel } from "../../lib/models";
import configs from "../../lib/configs";
import {Branding} from "./components/Branding/Branding";

interface VodTemplateSpec extends Lightning.Component.TemplateSpec {
    Branding: typeof Branding;
    List: typeof CategoryList;
}

interface VodTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class Vod
    extends Lightning.Component<VodTemplateSpec, VodTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<VodTemplateSpec>
{
    _lastLocale = Language.get();
    _branding: BrandingModel[] = [];

    static override _template(): Lightning.Component.Template<VodTemplateSpec> {
        return {
            Branding: {
                type: Branding
            },
            List: {
                type: CategoryList,
                x: theme.menu.w,
                requestMoreItems: getDashboard
            }
        };
    }

    readonly List = this.getByRef("List")!;
    readonly Branding = this.getByRef("Branding")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Branding;
    }

    override _firstActive() {
        this.application.emit("setActivePage", PAGES.vod.name);
    }

    override _active() {
        console.log("configs.modules.branding", configs.modules.branding);
        if (!configs.modules.branding) {
            this._setState("ListState");
        }

        window.seasonSelectedIndex = undefined;
        window.selectedIndex = undefined;
        this.application.emit("setActivePage", PAGES.vod.name);
    }

    override _inactive() {
        this._lastLocale = Language.get();

        this.stage.gc();
    }

    override _detach() {
        window.reloadContinueWatching = false;
    }

    // override _handleBack() {
    // if (!Router.getHistory().length) return true;
    // Router.go(Router.getHistory().length * -1);
    // }

    set branding(value: BrandingModel[]) {
        this._branding = value;
        this.Branding.patch({
            data: this._branding
        });

        if (!this._branding.length) {
            this._setState("ListState");
        }
    }

    set data(value: CategoryModel[]) {
        this.application.emit("clearBackground");

        if (this._lastLocale !== Language.get()) {
            this.List.reload(value);
        } else {
            this.List.addItems(value);
        }

        this._lastLocale = Language.get();
    }

    override _handleDown() {
        this._setState("ListState");
    }

    static override _states() {
        return [
            class ListState extends this {
                override _getFocused() {
                    return this.List;
                }

                override _handleUp() {
                    if (this._branding.length) {
                        this._setState("");
                    }
                }

                override $enter() {
                    this.Branding.patch({
                        smooth: {
                            alpha: 0
                        }
                    });
                }

                override $exit() {
                    this.Branding.patch({
                        smooth: {
                            alpha: 1
                        }
                    });
                }
            }
        ];
    }
}
