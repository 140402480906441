// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";
import { Colors, Language, Lightning, Registry, Router } from "@lightningjs/sdk";
import { MoviePosterWithLabel } from "../MoviePosterWithLabel/MoviePosterWithLabel";
import { redirectToSelectedMovie } from "../../lib/utils";
import { TranslatableText } from "../TranslatableText/TranslatableText";
import { MovieModel, TranslationModel } from "../../lib/models";
import theme from "../../lib/theme";
import { getContentByCategory, getContinueWatching, getTrending } from "../../api/queries";

interface MovieListTemplateSpec extends Lightning.Component.TemplateSpec {
    isContinueWatching: boolean;
    isTrending: boolean;

    categoryId: number;

    items: MovieModel[];
    // categoryTranslations: TranslationModel[];
    category: string;
    categoryKey: string;
    // smallerPosters: boolean;
    navigateOnSelect: boolean;

    offsetX: number;
    Category: typeof TranslatableText;
    ListContainer: {
        Placeholders: object;
        List: {
            type: typeof List;
        };
    };
}

export class MovieList
    extends Lightning.Component<MovieListTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MovieListTemplateSpec>
{
    _itemsLength = 0;
    _categoryTranslations!: TranslationModel[];
    _categoryId!: number;
    _category!: string;
    _categoryKey!: string;

    _smallerPosters = false;
    _navigateOnSelect = true;
    _isContinueWatching = false;
    _isTrending = false;

    _lang = Language.get();

    static override _template(): Lightning.Component.Template<MovieListTemplateSpec> {
        return {
            alpha: 0.01,
            collision: true,
            x: theme.banner.padding,
            Category: {
                type: TranslatableText,
                text: {
                    fontSize: 28,
                    fontFace: "Secondary-SemiBold",
                    textColor: Colors(theme.color.text).get()
                }
            },
            ListContainer: {
                collision: true,
                y: 16,
                Placeholders: {
                    alpha: 0.01,
                    y: 50,
                    w: theme.layout.screenW - theme.menu.w - 6,
                    flex: {
                        direction: "row"
                    }
                },
                List: {
                    alpha: 0,
                    collision: true,
                    // @ts-expect-error no types available yet
                    type: List,
                    direction: "row",
                    spacing: 42,
                    w: theme.layout.screenW - theme.menu.w - 6,
                    enableRequests: true,
                    requestThreshold: 12,
                    signals: { onRequestItems: true },
                    gcThreshold: 15,
                    scroll: {
                        after: 2
                    },
                    scrollTransition: {
                        duration: 0
                    }
                }
            }
        };
    }
    readonly Category = this.getByRef("Category")!;
    readonly ListContainer = this.getByRef("ListContainer")!;
    readonly Placeholders = this.ListContainer.getByRef("Placeholders")!;
    readonly List = this.ListContainer.getByRef("List")!;

    override _getFocused(): Lightning.Component | null | undefined {
        // @ts-expect-error no types available yet
        return this.List;
    }

    override _setup() {
        this.Placeholders.children = Array(7).fill({
            ...this._getMoviePosterProps(false),
            flexItem: {
                marginRight: 30
            }
        });
    }

    _clearList() {
        // @ts-expect-error no types available yet
        this.List.clear();
    }

    override _active() {
        this.fadeIn();

        // @ts-expect-error no types available yet
        if (this.List.items.length === 0) {
            this._reloadItems();
        }
    }

    override _handleBack() {
        // @ts-expect-error no types available yet
        if (this.List.index > 0) {
            // @ts-expect-error no types available yet
            this.List.index = 0;
            return true;
        }

        return false;
    }

    async _reloadItems() {
        this.resetItems(await this.onRequestItems());
    }

    _setItems(data: MovieModel[]) {
        // if (this._isContinueWatching && window.reloadCategories) return;
        this._itemsLength = data.length;

        if (data.length) {
            // @ts-expect-error no types available yet
            if (this.List.items.length === 0) {
                this.List.patch({
                    // @ts-expect-error no types available yet
                    scrollTransition: {
                        duration: 0
                    }
                });
            }

            const items = data.map((item: any) => {
                return item.data
                    ? {
                          ...this._getMoviePosterProps(item.data.__typename === "Episode"),
                          data: item.data
                      }
                    : {
                          ...this._getMoviePosterProps(item.__typename === "Episode"),
                          data: item
                      };
            });

            this.List.patch({
                // @ts-expect-error no types
                spacing: items[0].__typename === "Episode" ? theme.episodeItem.gap : theme.mediaItem.gap
            });

            this.List.add(items);

            this._enableAnimation();

            for (const wrapper of this.List.children) {
                wrapper?.patch({
                    collision: true
                });
            }

            this.List.patch({
                smooth: {
                    alpha: 1
                }
            });

            this.Placeholders.patch({
                smooth: {
                    alpha: 0.01
                }
            });
        } else {
            // this.List.clear();
        }
    }

    _update() {
        this.Placeholders.patch({
            // @ts-expect-error no types available yet
            alpha: this.List.items.length ? 0.01 : 1
        });

        this.Category.patch({
            backupValue: this._categoryKey || this._category,
            key: this._categoryKey ? `category.${this._categoryKey?.toLowerCase()}` : this._category
        });

        this.List.patch({ y: 50 });
    }

    _getMoviePosterProps(isEpisode: boolean) {
        return {
            type: MoviePosterWithLabel,
            w: isEpisode ? theme.episodeItem.posterW : theme.mediaItem.posterW,
            h: isEpisode ? theme.episodeItem.posterH : theme.mediaItem.posterH,
            posterHeight: isEpisode ? theme.episodeItem.posterH : theme.mediaItem.posterH,
            isContinueWatching: this._isContinueWatching
        };
    }

    _enableAnimation() {
        Registry.setTimeout(() => {
            this.List.patch({
                // @ts-expect-error no types available yet
                scrollTransition: {
                    duration: 0.4,
                    timingFunction: "linear"
                }
            });
        }, 400);
    }

    resetItems(data: any) {
        if (!data.length) return;

        if (data[0].id) {
            data = data.map((item: MovieModel) => ({
                ...this._getMoviePosterProps(item.__typename === "Episode"),
                data: item
            }));
        }

        // @ts-ignore
        this.List.reload(data);

        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }

        this.List.patch({
            smooth: {
                alpha: 1
            }
        });

        this.Placeholders.patch({
            smooth: {
                alpha: 0.01
            }
        });
        this._enableAnimation();
    }

    async onRequestItems() {
        // if (this._categoryId && this._categoryId > 0 && !this.List.items.length) {
        const data = this._isContinueWatching
            ? //@ts-expect-error no types
              this.List.items.length === 0
                ? await getContinueWatching()
                : []
            : this._isTrending
              ? //@ts-expect-error no types
                this.List.items.length === 0
                  ? await getTrending()
                  : []
              : this._categoryId
                ? await getContentByCategory(
                      this._categoryId,
                      // @ts-expect-error no types available yet
                      this.List.items.length
                  )
                : [];

        this.fireAncestors("$categoryMoviesLoaded", { id: this._categoryId, movies: data });

        return data.map((item: MovieModel) => ({
            ...this._getMoviePosterProps(item.__typename === "Episode"),
            data: item
        }));
    }

    fadeIn() {
        this.patch({
            smooth: {
                alpha: 1
            }
        });
    }

    fadeOut() {
        this.patch({
            smooth: {
                alpha: 0.01
            }
        });
    }

    clear() {
        // @ts-expect-error no types available yet
        this.List.clear();
    }

    $movieClicked(movie: MovieModel) {
        // @ts-expect-error no types available yet
        const listIndex = this.List.items.findIndex((item: any) => {
            return item?.data?.id === movie.id;
        });

        // @ts-expect-error no types available yet
        if (listIndex > -1 && listIndex !== this.List.index) {
            // @ts-expect-error no types available yet
            this.List.setIndex(listIndex);
        }

        if (!this.hasFocus()) {
            // @ts-expect-error no types available yet,
            this.fireAncestors("$categorySelected", this.parent.componentIndex);
        }
    }

    $movieSelected(movie: MovieModel) {
        if (this._navigateOnSelect) {
            redirectToSelectedMovie({
                id: movie.id,
                type: movie.__typename,
                isFree: movie.isFree
            });
        } else {
            this.fireAncestors("$movieSelected", movie);
        }
    }

    static get height() {
        return theme.mediaItem.posterH + 158;
    }

    static get width() {
        return theme.layout.screenW;
    }

    get index() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set index(value: number) {
        // @ts-expect-error no types available yet
        this.List.setIndex(value);
    }

    set offsetX(value: number) {
        this.Category.patch({ x: value });
        this.ListContainer.patch({ x: value });
    }

    set smallerPosters(value: boolean) {
        this._smallerPosters = value;
    }

    set navigateOnSelect(value: boolean) {
        this._navigateOnSelect = value;
    }

    set categoryId(value: number) {
        this._categoryId = value;
    }

    get categoryId() {
        return this._categoryId;
    }

    set isContinueWatching(value: boolean) {
        this._isContinueWatching = value;
    }

    get isContinueWatching(): boolean {
        return this._isContinueWatching;
    }

    set isTrending(value: boolean) {
        this._isTrending = value;
    }

    get isTrending(): boolean {
        return this._isTrending;
    }

    set items(value: MovieModel[]) {
        if (this._isContinueWatching && window.reloadContinueWatching) {
            window.reloadContinueWatching = false;

            this._reloadItems();
        } else {
            this._setItems(value);
        }
    }

    get items() {
        // @ts-expect-error no types available yet
        return this.List.items;
    }

    set category(value: string) {
        this._category = value;

        this._update();
    }

    set categoryKey(value: string) {
        this._categoryKey = value;

        this._update();
    }

    get categoryKey() {
        return this._categoryKey;
    }

    set categoryTranslations(value: TranslationModel[]) {
        this._categoryTranslations = value;

        this._update();
    }

    get lastIndex() {
        // @ts-expect-error no types available yet
        return this.List.index;
    }

    set lastIndex(value: number) {
        // @ts-expect-error no types available yet
        if (this.List.items.length) {
            // @ts-expect-error no types available yet
            this.List.index = value;

            this._enableAnimation();
        }
    }

    get lang() {
        return this._lang;
    }

    set lang(value) {
        this._lang = value;
    }
}
