import { Lightning } from "@lightningjs/sdk";
import theme from "../../lib/theme";
import { CategoryModel } from "../../lib/models";
import { getMediaPosterProps } from "../../lib/utils";
import { MediaList } from "../../components/data-display/MediaList/MediaList";

interface VodTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof MediaList;
}

interface VodTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class MediaCategory
    extends Lightning.Component<VodTemplateSpec, VodTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<VodTemplateSpec>
{
    static override _template(): Lightning.Component.Template<VodTemplateSpec> {
        return {
            collision: true,
            h: theme.layout.h - theme.banner.padding.y * 2,
            w: theme.layout.w - theme.menu.w,
            x: theme.menu.w,
            y: theme.banner.padding.y,
            List: {
                type: MediaList,
                h: theme.layout.h,
                w: (w) => w - theme.banner.padding.x,
                collision: true,
                isFullScreen: true
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.List;
    }

    set data(value: CategoryModel) {
        console.log("value.items", value);

        const itemWidth = getMediaPosterProps(value.items[0]?.__typename).w;

        console.log("itemWidth", itemWidth, itemWidth + 42);

        const perPage = Math.floor((theme.layout.w - theme.banner.padding.x - theme.menu.w) / (itemWidth + 42));
        // console.log("per row", value.length, perPage, value.slice(0, perPage));

        // console.log('value.items', value.items)
        // this.List.items = value.slice(0, perPage);

        this.List.patch({
            categoryId: value.id ? value.id! : undefined,
            categoryKey: value.name,
            isFullScreen: true,
            items: value.items
        });
    }
}
