import { Colors, Img, Lightning, Registry, Router, Utils } from "@lightningjs/sdk";
import { SPLASH_TIME } from "../lib/utils";
import theme from "../lib/theme";
import { Lifecycle, Parameters } from "@firebolt-js/sdk";

interface BootTemplateSpec extends Lightning.Component.TemplateSpec {
    Splash: object;
}

interface BootTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}
//

export class Boot
    extends Lightning.Component<BootTemplateSpec, BootTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<BootTemplateSpec>
{
    _animation: any;

    static override _template(): Lightning.Component.Template<BootTemplateSpec> {
        return {
            w: theme.layout.w,
            h: theme.layout.h,
            rect: true,
            color: Colors(theme.color.bg.primary).get(),
            Splash: {
                w: theme.layout.w,
                h: theme.layout.h,
                alpha: 0.01,
                texture: Img(Utils.asset("images/splash.png")).cover(theme.layout.w, theme.layout.h)
            }
        };
    }

    override _init() {
        Lifecycle.ready();

        this._animation = this.tag("Splash")!.animation({
            duration: 0.4,
            repeat: 0,
            stopMethod: "immediate",
            actions: [{ p: "alpha", v: { 0: 0.01, 1: 1 } }]
        });
        this._animation.start();

        this.tag("Splash")!.on("txLoaded", () => {
            this._resume();
        });

        this.tag("Splash")!.on("txError", () => {
            this._resume();
        });
    }

    _resume() {
        Registry.setTimeout(() => {
            this._animation.stop();
            Router.resume();
        }, SPLASH_TIME);
    }
}
