import { Lightning } from "@lightningjs/sdk";

// @ts-expect-error no types available yet
import { List } from "@lightningjs/ui";

import { SettingsPrivacy } from "./SettingsInfo/SettingsPrivacy";
import { SettingsContact } from "./SettingsInfo/SettingsContact";
import { SettingsItemContainer } from "../../pages/Settings";
import theme from "../../lib/theme";

interface SettingsInfoListTemplateSpec extends Lightning.Component.TemplateSpec {
    List: typeof List;
}

interface SettingsInfoListTypeConfig extends Lightning.Component.TypeConfig {
    IsPage: true;
}

export class SettingsInfoList
    extends Lightning.Component<SettingsInfoListTemplateSpec, SettingsInfoListTypeConfig>
    implements Lightning.Component.ImplementTemplateSpec<SettingsInfoListTemplateSpec>
{
    static override _template(): Lightning.Component.Template<SettingsInfoListTemplateSpec> {
        return {
            collision: true,
            w: (w) => w,
            h: 2 * theme.settings.itemH,
            List: {
                collision: true,
                w: (w: number) => w,
                h: (h: number) => h,
                x: 0,
                type: List,
                direction: "column",
                items: [
                    { type: SettingsItemContainer, content: SettingsPrivacy },
                    { type: SettingsItemContainer, content: SettingsContact }
                ]
            }
        };
    }

    readonly List = this.getByRef("List")!;

    override _getFocused() {
        return this.List;
    }

    override _setup() {
        for (const wrapper of this.List.children) {
            wrapper?.patch({
                collision: true
            });
        }
    }

    $listItemHover(e: any) {
        const index = this.List.items.findIndex((item: any) => item === e.parent);

        this.List.setIndex(index);

        // @ts-ignore
        this.signal("handleHover");

        return true;
    }
}
