import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { MediaPoster } from "../MediaPoster/MediaPoster";
import { MediaModel } from "../../../lib/models";
import { TranslatableText } from "../TranslatableText/TranslatableText";
import theme from "../../../lib/theme";
import { getMediaPosterProps } from "../../../lib/utils";

interface MediaPosterWithLabelTemplateSpec extends Lightning.Component.TemplateSpec {
    data: MediaModel;
    isContinueWatching: boolean | undefined;
    posterHeight: number;
    Poster: typeof MediaPoster;
    Label: object;
    Badge: {
        BadgeInner: {
            Content: {
                Icon: object;
                Text: typeof TranslatableText;
            };
        };
    };
}

export class MediaPosterWithLabel
    extends Lightning.Component<MediaPosterWithLabelTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<MediaPosterWithLabelTemplateSpec>
{
    _data: MediaModel | undefined;
    _isContinueWatching: boolean = false;

    static override _template(): Lightning.Component.Template<MediaPosterWithLabelTemplateSpec> {
        return {
            // flex: { direction: "column" },
            collision: true,
            zIndex: 10,
            Poster: {
                w: (w) => w,
                type: MediaPoster,
                scale: 1,
                pivotX: 0.5,
                pivotY: 0.5
            },
            Label: {
                w: (w) => w,
                // flexItem: { marginTop: 36 },
                x: 2,
                text: {
                    text: "",
                    fontSize: 28,
                    lineHeight: 30,
                    fontFace: "Secondary-Regular",
                    textAlign: "left",
                    maxLines: 3,
                    textOverflow: "ellipsis",
                    textColor: Colors(theme.color.text.primary).get()
                }
            },
            Badge: {
                flex: {
                    direction: "row",
                    justifyContent: "flex-end"
                },
                w: (w) => w - 12,
                y: (h) => h - 142,
                h: 30,
                BadgeInner: {
                    alpha: 0,
                    h: (h) => h,
                    flex: {
                        direction: "row",
                        paddingLeft: 6,
                        paddingRight: 6
                    },
                    shader: {
                        type: Lightning.shaders.RoundedRectangle,
                        radius: 4
                    },
                    Content: {
                        flexItem: { grow: 0, shrink: 1 },
                        flex: { direction: "row", paddingLeft: 6, paddingRight: 6 },
                        Icon: {},
                        Text: {
                            y: (h) => h / 2 + 1,
                            mountY: 0.5,
                            type: TranslatableText,
                            text: {
                                fontSize: 16,
                                textAlign: "center"
                            }
                        }
                    }
                }
            }
        };
    }

    readonly Poster = this.getByRef("Poster")!;
    readonly Label = this.getByRef("Label")!;
    readonly Badge = this.getByRef("Badge")!.getByRef("BadgeInner")!;
    readonly BadgeContent = this.Badge.getByRef("Content")!;
    readonly BadgeText = this.BadgeContent.getByRef("Text")!;

    override _getFocused(): Lightning.Component | null | undefined {
        return this.Poster;
    }

    set data(value: any) {
        const data = value.data || value;
        this._data = data;

        this.Label.patch({
            y: getMediaPosterProps(this._data?.__typename).h + 22,
            text: {
                text: this._data?.title || ""
            }
        });

        const isChannel = data.__typename === "ChannelContent" || data.__typename === "LiveTv";

        this.Poster.patch({
            data: this._data,
            useBackground: isChannel
        });

        if (this._data?.isLiveNow) {
            this.Badge.patch({
                alpha: 1,
                rect: true,
                color: Colors("#E50914").get()
            });
            this.BadgeContent.patch({
                Icon: {
                    flexItem: { marginRight: 6 },
                    w: 24,
                    h: 24,
                    y: (h) => h / 2,
                    mountY: 0.5,
                    src: Utils.asset(`icons/live-now.png`)
                }
            });
            this.BadgeText.patch({
                key: "general.liveNow"
            });
        } else if (this._data?.isFree) {
            this.Badge.patch({
                alpha: 1,
                rect: true,
                color: Colors(theme.color.primary).get()
            });
            this.BadgeText.patch({
                key: "general.free"
            });
        }
    }

    get data(): MediaModel {
        return this._data!;
    }

    set posterHeight(value: number) {
        this.Poster.patch({
            h: value
        });
    }

    set isContinueWatching(value: boolean) {
        this._isContinueWatching = value;
    }

    override _focus() {
        this.Poster.patch({ smooth: { scale: 1.08 } });

        if (this._data) {
            if (!this._isContinueWatching && this._data.__typename === "Episode") {
                window.selectedMovie = {
                    ...window.selectedMovie!,
                    title: this._data.title,
                    duration: this._data.duration,
                    description:
                        this._data.description ||
                        window.selectedMovie?.series?.description ||
                        window.selectedMovie?.description ||
                        "",
                    series: window.selectedMovie?.series || window.selectedMovie
                };
            } else {
                window.selectedMovie = this._data;
            }

            this.application.emit("updateSelectedMovie", window.selectedMovie!);
        }
    }

    override _unfocus() {
        this.Poster.patch({ smooth: { scale: 1 } });
    }

    override _handleEnter() {
        this._data && this.fireAncestors("$movieSelected", this._data);
    }

    _handleClick() {
        if (this.hasFocus()) {
            this._handleEnter();
        } else {
            this._data && this.fireAncestors("$movieClicked", this._data);
        }
    }
}
