import { Colors, Lightning } from "@lightningjs/sdk";
import { TranslatableText } from "../../../../components/data-display/TranslatableText/TranslatableText";
import theme from "../../../../lib/theme";
import { IAPEmailKeyboardInputs } from "./components/IAPEmailKeyboard/IAPEmailKeyboardInputs";
import { Button } from "../../../../components/input/Button/Button";

interface IAPEmailTemplateSpec extends Lightning.Component.TemplateSpec {
    action: "login" | "signup";
    loginData: any;
    Fields: {
        Name: typeof IAPEmailKeyboardInputs;
        Email: typeof IAPEmailKeyboardInputs;
        Password: typeof IAPEmailKeyboardInputs;
        ConfirmPassword: typeof IAPEmailKeyboardInputs;
    };

    Button: typeof Button;
    Link: any;
    Keyboard: any;
}

export class IAPEmail
    extends Lightning.Component<IAPEmailTemplateSpec>
    implements Lightning.Component.ImplementTemplateSpec<IAPEmailTemplateSpec>
{
    _action: "login" | "signup" = "login";
    _isValid = false;

    readonly Fields = this.getByRef("Fields")!;
    readonly Name = this.Fields.getByRef("Name")!;
    readonly Email = this.Fields.getByRef("Email")!;
    readonly Password = this.Fields.getByRef("Password")!;
    readonly ConfirmPassword = this.Fields.getByRef("ConfirmPassword")!;
    readonly Button = this.getByRef("Button")!;
    readonly LinkPlaceholder = this.getByRef("Link")!.getByRef("Text");
    readonly Link = this.getByRef("Link")!.getByRef("TextLink");
    readonly Keyboard = this.getByRef("Keyboard")!;

    static override _template(): Lightning.Component.Template<IAPEmailTemplateSpec> {
        return {
            w: 650,
            h: 650,
            flex: { direction: "column", justifyContent: "center", alignItems: "center" },
            Fields: {
                h: 420,
                flex: { direction: "column", justifyContent: "center", alignItems: "center" },
                Name: {
                    flexItem: {
                        marginBottom: 20
                    },
                    type: IAPEmailKeyboardInputs,
                    label: "auth.name"
                },
                Email: {
                    flexItem: {
                        marginBottom: 20
                    },
                    type: IAPEmailKeyboardInputs,
                    label: "auth.email"
                },
                Password: {
                    flexItem: {
                        marginBottom: 20
                    },
                    type: IAPEmailKeyboardInputs,
                    label: "auth.password"
                },
                ConfirmPassword: {
                    flexItem: {
                        marginBottom: 20
                    },
                    type: IAPEmailKeyboardInputs,
                    label: "auth.confirmPassword"
                }
            },
            Button: {
                flexItem: {
                    marginTop: 28,
                    marginBottom: 28
                },
                w: 380,
                useBorder: true,
                type: Button,
                label: "auth.action.login",
                disabled: true
            },
            Link: {
                flex: {
                    direction: "row"
                },
                Text: {
                    flexItem: {
                        marginRight: 6
                    },
                    type: TranslatableText,
                    key: "auth.login.info",
                    text: {
                        textAlign: "left",
                        fontSize: 24,
                        textColor: Colors(theme.color.text.tertiary).get()
                    }
                },
                TextLink: {
                    type: TranslatableText,
                    key: "auth.action.signup",
                    text: {
                        textAlign: "left",
                        fontSize: 24,
                        textColor: Colors(theme.color.text.tertiary).get()
                    }
                }
            }
        };
    }

    override _getFocused(): any {
        return this.Email;
    }

    override _handleDown() {
        this._setState("PasswordState");
    }

    override _handleUp() {
        const isLogin = this._action === "login";

        if (!isLogin) {
            this._setState("NameState");
        }
    }

    override _handleEnter() {
        this.signal("onShowKeyboard", {
            field: "email"
        });
    }

    override _focus() {
        this._update();
    }

    override _active() {
        this._update();
    }

    _update() {
        const isLogin = this._action === "login";

        this.Name.patch({
            alpha: isLogin ? 0 : 1
        });

        this.ConfirmPassword.patch({
            alpha: isLogin ? 0 : 1
        });

        this.Button.patch({
            label: isLogin ? "auth.action.login" : "auth.action.signup"
        });

        this.LinkPlaceholder.patch({
            key: isLogin ? "auth.login.info" : "auth.signup.info"
        });

        this.Link.patch({
            key: isLogin ? "auth.action.signup" : "auth.action.login"
        });
    }

    static override _states() {
        return [
            class NameState extends this {
                override _getFocused() {
                    return this.Name;
                }
                override _handleDown() {
                    this._setState("");
                }
                override _handleUp() {}

                override _handleEnter() {
                    this.signal("onShowKeyboard", {
                        field: "name"
                    });
                }
            },
            class PasswordState extends this {
                override _getFocused() {
                    return this.Password;
                }
                override _handleDown() {
                    const isLogin = this._action === "login";

                    if (isLogin) {
                        this._setState("ButtonState");
                    } else {
                        this._setState("ConfirmPasswordState");
                    }
                }
                override _handleUp() {
                    this._setState("");
                }

                override _handleEnter() {
                    this.signal("onShowKeyboard", {
                        field: "password"
                    });
                }
            },
            class ConfirmPasswordState extends this {
                override _getFocused() {
                    return this.ConfirmPassword;
                }
                override _handleDown() {
                    this._setState("ButtonState");
                }
                override _handleUp() {
                    this._setState("PasswordState");
                }

                override _handleEnter() {
                    this.signal("onShowKeyboard", {
                        field: "confirmPassword"
                    });
                }
            },
            class ButtonState extends this {
                override _getFocused() {
                    return this.Button;
                }
                override _handleDown() {
                    this._setState("LinkState");
                }
                override _handleUp() {
                    const isLogin = this._action === "login";

                    if (isLogin) {
                        this._setState("PasswordState");
                    } else {
                        this._setState("ConfirmPasswordState");
                    }
                }

                override _handleEnter() {
                    if (this._isValid) {
                        this.signal("onLogin");
                    }
                }
            },

            class LinkState extends this {
                override _getFocused() {
                    return this.Link;
                }

                override _handleDown() {}

                override _handleUp() {
                    this._setState("ButtonState");
                }

                override _handleEnter() {
                    this.signal("onChangeAction");
                }

                override $enter() {
                    this.LinkPlaceholder.patch({
                        text: {
                            textColor: Colors(theme.color.text.tertiary).get()
                        }
                    });
                    this.Link.patch({
                        text: {
                            textColor: Colors(theme.color.primary).get()
                        }
                    });
                }

                override $exit() {
                    this.LinkPlaceholder.patch({
                        text: {
                            textColor: Colors(theme.color.text.tertiary).get()
                        }
                    });
                    this.Link.patch({
                        text: {
                            textColor: Colors(theme.color.text.tertiary).get()

                        }
                    });
                }
            }
        ];
    }

    set action(value: "login" | "signup") {
        this._action = value;

        this._update();
    }

    set loginData(value: any) {
        const isLoginValid = value.email.length > 0 && value.password.length >= 6;
        this._isValid =
            this._action === "login"
                ? isLoginValid
                : isLoginValid && value.name.length > 0 && value.password === value.confirmPassword;

        this.Name.patch({
            label: value.name || "auth.name"
        });
        this.Email.patch({
            label: value.email || "auth.email"
        });
        this.Password.patch({
            label: value.password ? value.password.replace(/./g, "*") : "auth.password"
        });
        this.ConfirmPassword.patch({
            label: value.confirmPassword ? value.confirmPassword.replace(/./g, "*") : "auth.confirmPassword"
        });

        this.Button.patch({
            disabled: !this._isValid
        });
    }
}
